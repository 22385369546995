// Sentry
import * as Sentry from '@sentry/browser'
const { REACT_APP_SENTRY_DSN } = process.env

export const sentry = (): void => {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,

    // Alternatively, use `process.env.npm_package_version` for a dynamic Makefile version
    // if your build tool supports it.
    environment: 'production',
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/nexus-ai\.io\/api/]
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0
  })
}
