import React, { type FC, type PropsWithChildren } from 'react'
import { PageLoader } from 'components/index'
import { useGetUserQuery } from 'services/user'
import Cookies from 'js-cookie'

export const AuthMiddleware: FC<PropsWithChildren> = ({ children }) => {
  const { isSuccess, isLoading: userLoading } = useGetUserQuery(null)

  if (userLoading) {
    return <PageLoader/>
  }

  if (!userLoading && !isSuccess) {
    Cookies.remove('accessToken')
  }

  return children
}
