import React, { type FC, useCallback, useState } from 'react'
import styles from './styles.module.scss'

// NPM
import { useNavigate } from 'react-router'
import clsx from 'clsx'
import moment from 'moment'

// Components
import { Button, SkeletonLoader } from 'components/index'

// Icons
import { SettingsOutlined, StarOutlined } from 'icons/index'

// Redux
import { useAppSelector } from 'hooks/useRedux'

// Types
import { DefaultAvatar } from 'images/index'

enum SUBSCRIPTION_STATUS {
  CANCELED = 'Canceled',
  PAID = 'NexusPRO',
}

enum SUBSCRIPTION_BACKGROUND_COLOR {
  CANCELED = '#D23535',
  TRIALING = '#DCAC1F',
  PAID = '#29B473'
}

const UserCard: FC = () => {
  const navigate = useNavigate()
  const user = useAppSelector(state => state.user.user)
  const subscription = useAppSelector(state => state.cards.subscription)
  const [hasImageLoaded, setHasImageLoaded] = useState(false)

  const handleNavigateToSettings = (): void => {
    navigate('/settings/profile')
  }

  const getDaysUntilTrialEnds = useCallback(() => {
    const targetDate = moment(subscription?.trialEnd, 'DD MMMM').add(1, 'days')
    const currentDate = moment()

    return targetDate.diff(currentDate, 'days')
  }, [subscription !== null])

  const getSubscriptionStatus = useCallback(() => {
    return subscription?.status === 'trialing'
      ? `Free trial: ${getDaysUntilTrialEnds()} days left`
      : subscription?.status === 'canceled'
        ? SUBSCRIPTION_STATUS.CANCELED
        : SUBSCRIPTION_STATUS.PAID
  }, [subscription?.status])

  const handleChangeLoadingStatus = (): void => {
    setHasImageLoaded(prevState => !prevState)
  }

  return <div className={clsx('flex flex-col relative w-full pt-5 pb-2.5', styles.wrapper)}>
    <div
      style={{ backgroundColor: subscription?.status === 'canceled' ? SUBSCRIPTION_BACKGROUND_COLOR.CANCELED : subscription?.status === 'trialing' ? SUBSCRIPTION_BACKGROUND_COLOR.TRIALING : SUBSCRIPTION_BACKGROUND_COLOR.PAID }}
      className={styles.trialPlaceholder}>
      <p className={styles.trialPlaceholderDays}>
        {getSubscriptionStatus()}
      </p>
    </div>
    <div className={'flex flex-row gap-4 relative items-center px-5'}>
    {!hasImageLoaded && <SkeletonLoader />}
      <img className={styles.avatar}
           width={40}
           onLoad={handleChangeLoadingStatus}
           height={40}
           src={user?.avatar ?? DefaultAvatar}
           alt="avatar"/>

      <div className={'flex flex-col'}>
        <p className={styles.username}>{user?.fullname}</p>
        <p className={styles.email}>{user?.email}</p>
      </div>
    </div>

    <div className={'flex flex-col gap-4 mt-5 px-2.5'}>
      <Button renderIcon={<StarOutlined/>} disabled={false} onClick={() => null} title={'Upgrade to NexusPro'}/>
      <button onClick={handleNavigateToSettings} className={styles.settings}>
        <SettingsOutlined/>
        Settings
      </button>
    </div>
  </div>
}

export default UserCard
