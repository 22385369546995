import React, { type FC } from 'react'
import styles from './styles.module.scss'

// Components
import PromptExampleItem from 'components/PromptExampleList/PromptExampleItem'
import clsx from 'clsx'

const _prompts = [
  {
    _id: 0,
    message: '“What is the occupancy group for a restaurant per 2022 building code?”'
  },
  {
    _id: 1,
    message: '“What is the maximum travel distance for sprinklered space in a ground floor restaurant per 2022 building code?”'
  },
  {
    _id: 2,
    message: '“What is the minimum dimensions of a bedroom under per 2022 building code?”'
  }
]

const PromptsExampleList: FC = () => {
  return <>
    <div className={clsx('pt-12 flex flex-col px-2 justify-center gap-6 lg:gap-12 items-center overflow-y-scroll', styles.noScrollBar)}>
      <h2 className={styles.heading}>Prompt examples:</h2>
      <div className={'flex flex-col gap-4'}>
        {_prompts.map((prompt) => <PromptExampleItem key={prompt._id} prompt={prompt}/>)}
      </div>
    </div>
  </>
}

export default PromptsExampleList
