import React, { type ChangeEvent, useState } from 'react'
import styles from './styles.module.scss'

// NPM
import { useNavigate } from 'react-router'
import clsx from 'clsx'

// Components
import { Container } from 'layout/index'
import { Button, InputWithIcon } from 'components/index'

// Icons
import { LockSolid, VisibilityActive, VisibilityDisabled } from 'icons/index'

const ResetPasswordNewPassword: React.FC = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [passwords, setPasswords] = useState({
    new: '',
    retype: ''
  })

  const [hasPasswordsSecured, setHasPasswordsSecured] = useState({
    new: true,
    retype: true
  })

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>): void => {
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value
    })
  }

  const handleChangeNewPasswordVisibility = (): void => {
    setHasPasswordsSecured({
      ...hasPasswordsSecured,
      new: !hasPasswordsSecured.new
    })
  }

  const handleChangeRetypedPasswordVisibility = (): void => {
    setHasPasswordsSecured({
      ...hasPasswordsSecured,
      retype: !hasPasswordsSecured.retype
    })
  }

  const handleResetPassword = (): void => {
    setIsLoading(true)

    setTimeout(() => {
      setIsLoading(false)
      navigate('/')
    }, 500)
  }

  return <Container>
    <div className={clsx('w-full flex flex-col items-center', styles.wrapper)}>
      <h1 className={styles.heading}>Create a new password</h1>

      <div className={'w-full flex flex-col gap-6 justify-center mt-16 sm:mt-8'}>
        <InputWithIcon onChange={handleChangePassword}
                       onRightIconPress={handleChangeNewPasswordVisibility}
                       iconLeft={<LockSolid/>}
                       iconRight={hasPasswordsSecured.new ? <VisibilityDisabled/> : <VisibilityActive/>}
                       placeholder={'Enter your new password'}
                       type={hasPasswordsSecured.new ? 'password' : 'text'}
                       name={'new'}
                       value={passwords.new}/>

        <InputWithIcon onChange={handleChangePassword}
                       onRightIconPress={handleChangeRetypedPasswordVisibility}
                       iconLeft={<LockSolid/>}
                       iconRight={hasPasswordsSecured.retype ? <VisibilityDisabled/> : <VisibilityActive/>}
                       placeholder={'Enter your new password again'}
                       type={hasPasswordsSecured.retype ? 'password' : 'text'}
                       name={'retype'}
                       value={passwords.retype}/>
      </div>

      <div className={'w-full mt-52 sm:mt-8'}>
        <Button disabled={false} onClick={handleResetPassword} isLoading={isLoading} title={'Continue'}/>
      </div>
    </div>
  </Container>
}

export default ResetPasswordNewPassword
