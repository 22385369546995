import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ChatsState } from './type'
import type IChat from 'types/IChat'
import { ChatService } from 'services/chat'

const initialState: ChatsState = {
  chats: null,
  loading: false,
  chat: {
    state: null,
    id: null
  }
}

const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setChats: (state, action: PayloadAction<{ chats: IChat[] }>) => {
      state.chats = action.payload.chats
    },
    removeChat: (state, action: PayloadAction<{
      state: null | boolean
      id: null | string
    }>) => {
      state.chat.state = action.payload.state
      state.chat.id = action.payload.id
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      ChatService.endpoints.getChats.matchFulfilled,
      (state, { payload }) => {
        state.chats = payload
      }
    )
    builder.addMatcher(
      ChatService.endpoints.deleteChat.matchPending,
      (state) => {
        state.loading = true
      }
    )
    builder.addMatcher(
      ChatService.endpoints.deleteChat.matchFulfilled,
      (state) => {
        state.loading = false
      }
    )
    builder.addMatcher(
      ChatService.endpoints.deleteChat.matchRejected,
      (state) => {
        state.loading = false
      }
    )
  }
})

export const {
  setChats,
  removeChat
} = chatsSlice.actions
export default chatsSlice.reducer
