// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_description__S4rp7 {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.16px;
  text-align: center;
}

.styles_cancel__oKNEK {
  border: 2px solid #343839;
  color: #313333;
}

.styles_accept__rrUmd {
  background-color: #D23535;
  color: #FEFEFE;
}

.styles_button__l0mDt {
  max-width: 232px;
  width: 100%;
  padding: 0 24px;
  border-radius: 12px;
  height: 48px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

@media screen and (min-width: 568px) {
  .styles_description__S4rp7 {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/CancelSubscription/styles.module.scss","webpack://./src/constants/fonts.scss","webpack://./src/constants/colors.scss"],"names":[],"mappings":"AAGA;EACE,kCCFQ;EDGR,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,uBAAA;EACA,kBAAA;AADF;;AAIA;EACE,yBAAA;EACA,cEdM;AFaR;;AAIA;EACE,yBAAA;EACA,cEhBM;AFeR;;AAIA;EACE,gBAAA;EACA,WAAA;EACA,eAAA;EACA,mBAAA;EACA,YAAA;EAEA,kCC5BQ;ED6BR,gBAAA;EACA,eAAA;AAFF;;AAKA;EACE;IACE,eAAA;EAFF;AACF","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n.description {\n  font-family: $poppins;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px; /* 150% */\n  letter-spacing: -0.16px;\n  text-align: center;\n}\n\n.cancel {\n  border: 2px solid #343839;\n  color: $black;\n}\n\n.accept {\n  background-color: #D23535;\n  color: $white;\n}\n\n.button {\n  max-width: 232px;\n  width: 100%;\n  padding: 0 24px;\n  border-radius: 12px;\n  height: 48px;\n\n  font-family: $poppins;\n  font-weight: 600;\n  font-size: 16px;\n}\n\n@media screen and (min-width: 568px) {\n  .description {\n    font-size: 16px;\n  }\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `styles_description__S4rp7`,
	"cancel": `styles_cancel__oKNEK`,
	"accept": `styles_accept__rrUmd`,
	"button": `styles_button__l0mDt`
};
export default ___CSS_LOADER_EXPORT___;
