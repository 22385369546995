import React, { type FC } from 'react'
import { OverlayWrapper } from 'components/index'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { removeChat } from 'store/chats/slice'

import styles from './styles.module.scss'
import clsx from 'clsx'
import { CrossBlack } from 'icons/index'
import { useDeleteChatMutation } from 'services/chat'
import { useNavigate } from 'react-router'
import { resetChatItems } from 'store/chat-items/slice'

const DeleteChat: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const chat = useAppSelector(state => state.chats.chat)
  const [deleteChat] = useDeleteChatMutation()

  const handleDisableModal = (): void => {
    dispatch(removeChat({
      state: false,
      id: null
    }))
  }

  const handleDeleteChat = async (): Promise<void> => {
    if (chat.id !== null) {
      await deleteChat(chat.id)
      dispatch(removeChat({
        state: null,
        id: null
      }))
      dispatch(resetChatItems())
    }
    navigate('/chats')
  }

  return <OverlayWrapper handleToggleOverlay={handleDisableModal}>
    <div
      className={clsx('flex flex-col gap-6 px-8 pt-10 pb-8 relative md:px-12 items-center bg-white rounded-xl', styles.wrapper)}>
      <div className={'absolute right-2.5 top-2.5'}>
        <CrossBlack/>
      </div>
      <p className={styles.description}>Are you sure you want to delete this chat?</p>

      <div className={'w-full flex flex-row items-center justify-between gap-4'}>
        <button onClick={handleDisableModal} className={clsx(styles.button, styles.cancel)}>
          Cancel
        </button>

        <button onClick={handleDeleteChat} className={clsx(styles.button, styles.accept)}>
          Delete
        </button>
      </div>
    </div>
  </OverlayWrapper>
}

export default DeleteChat
