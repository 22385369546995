import React, { type FC, useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'

// NPM
import OtpInput from 'react18-input-otp'
import clsx from 'clsx'
import { RotatingLines } from 'react-loader-spinner'
import { useLocation, useNavigate } from 'react-router'
import { useCookies } from 'react-cookie'

// Components
// Layout
import { Container } from 'layout/index'
import { Button } from 'components/index'

// Services
import { useResendVerificationMutation, useVerifyUserMutation } from 'services/verify'

enum BUTTON_TEXT {
  RESET = 'Create a new password',
  VERIFY = 'Continue'

}

const Verification: FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [code, setCode] = useState<string>()
  const [timer, setTimer] = useState(0)
  const [, setCookies] = useCookies(['accessToken'])

  const [resendVerification] = useResendVerificationMutation()

  const [verifyUser, {
    data,
    isLoading,
    isError,
    isSuccess
  }] = useVerifyUserMutation()

  const timeOutCallback = useCallback(() => {
    setTimer((currTimer: any) => currTimer - 1)
  }, [])

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000)
  }, [timer, timeOutCallback])

  const handleChangeCode = (value: string): void => {
    setCode(value)
  }

  const handleCheckoutVerification = async (): Promise<void> => {
    await verifyUser({
      email: state.email,
      code: Number(code)
    })
  }

  const checkIsValidOtpCodeLen = useCallback(() => {
    let isDisabled = true

    if (code?.length === 6) isDisabled = false

    return isDisabled
  }, [code])

  useEffect(() => {
    if (isSuccess) {
      setCookies('accessToken', data?.accessToken)
      navigate('/personal')
    }
  }, [isSuccess])

  const handleResendCode = async (): Promise<void> => {
    await resendVerification({ email: state.email })
    setTimer(60)
  }

  return <Container>
    <div className={clsx('flex items-center w-full h-auto flex-col', styles.wrapper)}>
      <h1 className={styles.heading}>Check your email for a code</h1>
      <p className={styles.subheading}>Please enter a 6-digit code we’ve sent to <span
        style={{
          fontWeight: 600,
          color: '#29B473'
        }}>{state?.email}</span></p>
      <div className={'flex items-center justify-center w-full h-auto flex-col mt-8'}>
        <OtpInput value={code} onChange={handleChangeCode} isInputNum containerStyle={'max-[568px]:gap-2 gap-6'}
                  inputStyle={clsx(styles.codeField, {
                    [styles.codeFieldHighlighted]: isError
                  })} numInputs={6}/>

        {isLoading && <div className={'flex mt-6 justify-center w-full gap-4 items-center'}>
          <RotatingLines strokeColor={'#616A85'} width={'20'}/>
          <span className={styles.checking}>Checking your code</span>
        </div>}

        <button onClick={handleResendCode} className={clsx('mt-6', styles.resendAction)}>
          Resend code {timer <= 60 && timer !== 0 && `in 0:${timer < 10 ? `0${timer}` : `${timer}`}`}
        </button>

        <p className={clsx('mt-5 mb-8', styles.subheading)} style={{
          fontSize: 12,
          color: '#6C7275'
        }}>Can’t find the code? Check your spam folder</p>

        {!isLoading && <div className={'w-full mt-28 sm:mt-0'}>
          <Button
            disabled={checkIsValidOtpCodeLen()}
            isLoading={isLoading}
            onClick={handleCheckoutVerification}
            title={state?.isResetting ? BUTTON_TEXT.RESET : BUTTON_TEXT.VERIFY}/>
        </div>}
      </div>
    </div>
  </Container>
}

export default Verification
