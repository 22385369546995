// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_chat__sor4c {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #FEFEFE;
}

.styles_date__sknGG {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  color: #868686;
}`, "",{"version":3,"sources":["webpack://./src/layout/Dashboard/components/Sidebar/ChatsList/ChatItem/styles.module.scss","webpack://./src/constants/fonts.scss","webpack://./src/constants/colors.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,gCCDM;EDEN,gBAAA;EACA,cEHM;AFER;;AAIA;EACE,eAAA;EACA,gCCRM;EDSN,gBAAA;EACA,cAAA;AADF","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n.chat {\n  font-size: 16px;\n  font-family: $inter;\n  font-weight: 500;\n  color: $white;\n}\n\n.date {\n  font-size: 12px;\n  font-family: $inter;\n  font-weight: 300;\n  color: #868686;\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chat": `styles_chat__sor4c`,
	"date": `styles_date__sknGG`
};
export default ___CSS_LOADER_EXPORT___;
