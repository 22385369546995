import React, { type FC } from 'react'
import styles from './styles.module.scss'
import { CheckmarkCircleOutlined } from 'icons/index'
import clsx from 'clsx'

interface PropsType {
  active: boolean
  action: string
}

export const PopupView: FC<PropsType> = ({ active, action }) => {
  return <div className={clsx(styles.wrapper, { [styles.active]: active })}>
    <CheckmarkCircleOutlined />

    <span className={styles.heading}>{action}</span>
  </div>
}
