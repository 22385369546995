import React, { type FC } from 'react'
import styles from './styles.module.scss'

// Stripe API
import { Elements } from '@stripe/react-stripe-js'

// NPM
import clsx from 'clsx'

// Layout
import { Container } from 'layout/index'

// Components
import PaymentForm from 'pages/Subscription/components/PaymentForm'

// lib
import { stripeProvider } from 'lib/stripe'

const Subscription: FC = () => {
  const options = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap'
      }
    ]
  }

  return <Container>
    <div className={clsx('flex items-center justify-center w-full h-auto flex-col', styles.wrapper)}>
      <h1 className={styles.heading}>Get a free trial</h1>

      <p className={clsx('mt-8', styles.subheading)}>The free subscription will last 14 days and then you will be
        charged <span className={'min-[768px]:hidden xs:visible'} style={{ fontWeight: 600 }}>$13.99</span></p>

      <p className={clsx('min-[768px]:visible max-[768px]:hidden', styles.subheading)}
         style={{ fontWeight: 600 }}>$13.99</p>
      <div className={clsx('px-7 py-5 mt-8 w-full max-[568px]:px-4', styles.features)}>
        <h2 className={styles.featuresHeading}>Monthly subscription includes:</h2>
        <ul className={'flex flex-col mt-5 gap-2'}>
          <li className={styles.featuresListItem}><CircleListItem /> Unlimited ...</li>
          <li className={styles.featuresListItem}><CircleListItem /> More ...</li>
          <li className={styles.featuresListItem}><CircleListItem /> Easy ...</li>
        </ul>
      </div>
      <div className={'flex items-center flex-col w-full h-64 mt-8'}>
        <div className={'flex flex-row w-full'}>

          {/* STRIPE PAYMENT ELEMENT */}
          <Elements options={options} stripe={stripeProvider}>
            <PaymentForm />
          </Elements>

        </div>
      </div>
    </div>
  </Container>
}

export default Subscription

const CircleListItem: FC = () => {
  return <div className={'w-2.5 h-2.5 rounded-3xl bg-[#29B473]'}/>
}
