import React, { type FC, useState } from 'react'
import clsx from 'clsx'
import styles from 'components/Modals/NewPaymentMethod/styles.module.scss'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useAttachPaymentMutation } from 'services/billing'
import { type StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { CheckmarkCircleOutlined, Loader } from 'icons/index'

interface PropsType {
  handleToggleModal: () => void
}

const CARD_OPTIONS = {
  showIcon: false,
  style: {
    base: {
      fontSize: '14px',
      fontWeight: 500,
      color: 'black',
      fontFamily: 'Poppins, sans-serif',
      '::placeholder': {
        fontWeight: 500,
        color: '#BABABA'
      }
    },
    invalid: { color: 'black' }
  }
}

const PaymentForm: FC<PropsType> = ({ handleToggleModal }) => {
  // Stripe
  const stripe = useStripe()
  const elements = useElements()

  const [hasPaymentFilled, setHasPaymentFilled] = useState(false)

  const [attachPaymentMethod, { isLoading }] = useAttachPaymentMutation()
  const handleAttachNewMethod = async (event: any): Promise<void> => {
    event.preventDefault()

    if ((stripe == null) || (elements == null)) {
      return
    }

    const cardElement: any = elements.getElement(CardElement)

    const {
      error,
      paymentMethod
    } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })

    if ((paymentMethod?.id) != null) {
      await attachPaymentMethod(paymentMethod.id)
    }

    if (error == null) {
      handleToggleModal()
    }
  }

  const handleValidateCard = (details: StripeCardElementChangeEvent): void => {
    setHasPaymentFilled(details.complete)
  }

  return <form className={'w-full'} onSubmit={handleAttachNewMethod}>
    <div className={clsx('form-row flex relative items-center justify-start flex-row w-full', styles.paymentInput)}>
      <div className="FormRow w-[95%]">
        <CardElement options={CARD_OPTIONS} onChange={handleValidateCard}/>
      </div>
      {hasPaymentFilled && <div className={'absolute right-4'}><CheckmarkCircleOutlined/></div>}
    </div>
    <div className={'flex flex-row justify-between items-center gap-2 mt-7 sm:gap-6 w-full'}>
      <button onClick={handleToggleModal} className={clsx(styles.button, styles.cancel)}>Cancel</button>
      <button disabled={!hasPaymentFilled}
              className={clsx(styles.button, styles.create, { [styles.createDisabled]: !hasPaymentFilled })}>
        {isLoading ? <Loader width={20} height={20} /> : 'Add new payment method'}
      </button>
    </div>
  </form>
}

export default PaymentForm
