import React, { type FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

interface TabProps {
  item: string
  activeTab: string
  setActiveTab: (value: string) => void
}

const TabWrapper: FC<TabProps> = ({ item, activeTab, setActiveTab }) => {
  const handleChangeActiveTab = (value: string): void => {
    setActiveTab(value)
  }

  return <div onClick={() => { handleChangeActiveTab(item) }} className={clsx(styles.wrapper, {
    [styles.wrapperActive]: item === activeTab
  })}>
        <span className={clsx(styles.wrapperValue, {
          [styles.wrapperValueActive]: item === activeTab
        })}>{item}</span>
    </div>
}

export default TabWrapper
