import React, { type FC, useEffect, useRef } from 'react'
import MessageItem from 'components/MessagesList/MessageItem'
import clsx from 'clsx'
import styles from 'layout/Dashboard/styles.module.scss'
import { useAppSelector } from 'hooks/useRedux'
import type IChatItem from 'types/IChatItem'

const MessagesList: FC = () => {
  const messages = useAppSelector(state => state.chatItems.items)

  const listRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const lastItem = listRef.current?.lastElementChild
    lastItem?.scrollIntoView({ behavior: 'smooth' })
  }, [messages?.length])

  return <div ref={listRef}
              className={clsx('flex flex-col justify-start mt-3 items-start overflow-y-scroll w-full gap-4 xl:gap-10', styles.noScrollBar)}>
    {messages?.length && messages.map((message: IChatItem) => <MessageItem key={message.id} message={message}/>)}
  </div>
}

export default MessagesList
