import React, { type FC } from 'react'
import { SidebarLink } from 'pages/Settings/layout/components/Sidebar/SidebarLink'
import { ProfileSidebarFocused, ProfileSidebarUnfocused, StarSidebarFocused, StarSidebarUnfocused } from 'icons/index'

const routes = [
  {
    title: 'Personal Information',
    path: '/settings/profile',
    icon: {
      focused: ProfileSidebarFocused,
      unfocused: ProfileSidebarUnfocused
    }
  },
  {
    title: 'Manage Subscription',
    path: '/settings/billing',
    icon: {
      focused: StarSidebarFocused,
      unfocused: StarSidebarUnfocused
    }
  }
]

const Sidebar: FC = () => {
  return <>
        {routes.map((route) => <SidebarLink key={route.path} route={route} />)}
    </>
}

export default Sidebar
