import { type BaseQueryFn, createApi, type FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Packages
import Cookies from 'js-cookie'
import type ServerErrorResponse from 'types/ServerResponseError'
import type * as Types from './types'

const { REACT_APP_BASE_URI } = process.env

export const AuthService = createApi({
  reducerPath: 'AuthService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${String(REACT_APP_BASE_URI)}/auth`,
    prepareHeaders: (headers) => {
      const token: string | undefined = Cookies.get('accessToken')

      if (token != null) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    }
  }) as BaseQueryFn<string | FetchArgs, unknown, ServerErrorResponse>,
  endpoints: (builder) => ({
    loginUser: builder.mutation<Types.TokensResponse, Types.UserSignInCredentialsRequest>({
      query: (user) => ({
        url: '/sign-in',
        method: 'POST',
        body: user
      })
    }),
    loginViaGoogle: builder.mutation<Types.TokensResponse, Types.GoogleAuthRequest>({
      query: (token) => ({
        url: '/sign-in-google',
        method: 'POST',
        body: token
      })
    })
  })
})

export const {
  useLoginUserMutation,
  useLoginViaGoogleMutation
} = AuthService
