import React, { type ButtonHTMLAttributes, type FC } from 'react'
import styles from './styles.module.scss'

// NPM
import clsx from 'clsx'

// Icons
import { Loader } from 'icons/index'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled: any
  onClick?: () => void
  title: string
  isLoading?: boolean
  renderIcon?: React.JSX.Element
  bgColor?: 'black' | 'green'
}

enum BUTTON_BACKGROUND {
  black = '#313333',
  green = '#29B473'
}

const Button: FC<Props> = ({
  title,
  disabled,
  onClick,
  isLoading,
  renderIcon,
  type,
  bgColor = 'green'
}) => {
  return <button type={type} onClick={onClick} disabled={disabled === true || isLoading === true}
                 style={{ backgroundColor: BUTTON_BACKGROUND[bgColor] }}
                 className={clsx(styles.wrapper, {
                   [styles.disabled]: disabled,
                   [styles.withIcon]: renderIcon !== undefined
                 })}>
    {(isLoading !== true) && renderIcon !== undefined ? renderIcon : null}
    {(isLoading === true)
      ? <Loader width={20} height={20}/>
      : title}
  </button>
}

export default Button
