import React, { type FC, type PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'
// import { useAppSelector } from 'hooks/useRedux'
import Cookies from 'js-cookie'

export const ProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const token = Cookies.get('accessToken')

  if (!token) {
    return <Navigate to={'/'} />
  }

  return children
}
