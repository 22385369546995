// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_heading__SH1LX {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 1024px) {
  .styles_heading__SH1LX {
    font-size: 36px;
  }
}
.styles_noScrollBar__\\+DK56::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.styles_noScrollBar__\\+DK56 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}`, "",{"version":3,"sources":["webpack://./src/components/PromptExampleList/styles.module.scss","webpack://./src/constants/fonts.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,kCCFQ;EDGR,gBAAA;EAEA,aAAA;EACA,uBAAA;AADF;;AAIA;EACE;IACE,eAAA;EADF;AACF;AAIA;EACE,aAAA;AAFF;;AAKA,4CAAA;AACA;EACE,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;EACA,kBAAA;AAFF","sourcesContent":["@import 'constants/fonts.scss';\n\n.heading {\n  font-size: 24px;\n  font-family: $poppins;\n  font-weight: 500;\n\n  display: flex;\n  justify-content: center;\n}\n\n@media screen and (min-width: 1024px) {\n  .heading {\n    font-size: 36px;\n  }\n}\n\n.noScrollBar::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.noScrollBar {\n  -ms-overflow-style: none;  /* IE and Edge */\n  scrollbar-width: none;  /* Firefox */\n  overflow-y: scroll;\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `styles_heading__SH1LX`,
	"noScrollBar": `styles_noScrollBar__+DK56`
};
export default ___CSS_LOADER_EXPORT___;
