// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
  position: absolute;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #ccc;
}
.skeleton::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #ccc, #dedede, #ccc);
  animation: progress 1s ease-in-out infinite;
}

@keyframes progress {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SkeletonLoader/SkeletonLoader.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,gBATW;AAOb;AAIE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,uDAAA;EACA,2CAAA;AAFJ;;AAMA;EACE;IACE,mCAAA;EAHF;EAKA;IACE,kCAAA;EAHF;AACF","sourcesContent":["$color-base: #ccc;\n$color-highlight: lighten($color-base, 7%);\n\n.skeleton {\n  position: absolute;\n  overflow: hidden;\n  width: 40px;\n  height: 40px;\n  border-radius: 40px;\n  background: $color-base;\n  \n  &::after {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: linear-gradient(90deg, $color-base, $color-highlight, $color-base);\n    animation: progress 1s ease-in-out infinite;\n  }\n}\n\n@keyframes progress {\n  0% {\n    transform: translate3d(-100%, 0, 0);\n  }\n  100% {\n    transform: translate3d(100%, 0, 0);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
