import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'

// Cookies Provider
import { CookiesProvider } from 'react-cookie'

// Redux
import { Provider } from 'react-redux'
import { store } from './store'

// Helpers
import { AuthMiddleware } from 'helpers/AuthMiddleware'

// Lib
import { sentry } from 'lib/sentry'

// Environments
const { REACT_APP_ENVIRONMENT } = process.env

if (REACT_APP_ENVIRONMENT === 'production') { sentry() }

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <CookiesProvider>
              <AuthMiddleware>
                    <App/>
                </AuthMiddleware>
            </CookiesProvider>
        </Provider>
    </React.StrictMode>
)
