import React, { type FC } from 'react'
import styles from './styles.module.scss'
import TabWrapper from 'pages/Authorization/components/TabsWrapper/TabWrapper'

interface TabProps {
  items: string[]
  activeTab: string
  setActiveTab: (value: string) => void
}

const TabsWrapper: FC<TabProps> = ({ items, activeTab, setActiveTab }) => {
  return <div className={styles.wrapper}>
    {items.map((item, idx) => <TabWrapper activeTab={activeTab} setActiveTab={setActiveTab} key={idx} item={item} />)}
    </div>
}

export default TabsWrapper
