import React, { type FC } from 'react'
import Typed from 'react-typed'
import { LoaderGreen } from 'icons/index'
import styles from './styles.module.scss'

const PageLoader: FC = () => {
  return <div className={'w-full h-screen bg-[#FEFEFE] flex flex-col gap-4 justify-center items-center'}>
    <div>
      <LoaderGreen width={80} height={80}/>
    </div>

    <div className={'flex flex-row items-end'}>
      <span className={styles.loading}>Loading</span>
      <Typed className={styles.loading} loop typeSpeed={120} backSpeed={120} showCursor={false} strings={['...']}/>
    </div>

  </div>
}

export default PageLoader
