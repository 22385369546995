import { type BaseQueryFn, createApi, type FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Packages
import Cookies from 'js-cookie'
import type ServerErrorResponse from 'types/ServerResponseError'

import type * as Types from './types'

const { REACT_APP_BASE_URI } = process.env

export const VerificationService = createApi({
  reducerPath: 'VerificationService',
  baseQuery: fetchBaseQuery({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    baseUrl: `${REACT_APP_BASE_URI}/verify`,
    prepareHeaders: (headers) => {
      const token: string | undefined = Cookies.get('accessToken')

      if (token != null) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    }
  }) as BaseQueryFn<string | FetchArgs, unknown, ServerErrorResponse>,
  tagTypes: ['Verification'],
  endpoints: (builder) => ({
    verifyUser: builder.mutation<Types.TokensResponse, Types.UserVerifyRequest>({
      query: (email) => ({
        url: '',
        method: 'POST',
        body: email
      })
    }),
    resendVerification: builder.mutation<boolean, Types.UserResendRequest>({
      query: (email) => ({
        url: '/resend',
        method: 'POST',
        body: email
      })
    })
  })
})

export const { useVerifyUserMutation, useResendVerificationMutation } = VerificationService
