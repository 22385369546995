// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_sidebar__HslLt {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-repeat: no-repeat;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  max-width: 280px;
  animation: styles_sidebarFocus__nu-Xw 0.5s linear;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */
  -webkit-animation: styles_sidebarFocus__nu-Xw 0.5s ease forwards;
  -moz-animation: styles_sidebarFocus__nu-Xw 1s ease forwards;
  -o-animation: styles_sidebarFocus__nu-Xw 1s ease forwards;
  left: -50%;
  z-index: 999;
}

@keyframes styles_sidebarFocus__nu-Xw {
  from {
    left: -50%;
  }
  to {
    left: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/SidebarMenu/styles.module.scss"],"names":[],"mappings":"AAIA;EACE,yDAAA;EACA,2BAAA;EACA,4BAAA;EACA,6BAAA;EACA,gCAAA;EAEA,gBAAA;EACA,iDAAA;EACA,iCAAA;EAEA,yCAAA,EAAA,0BAAA;EAEA,gEAAA;EACA,2DAAA;EACA,yDAAA;EACA,UAAA;EACA,YAAA;AALF;;AASA;EACE;IACE,UAAA;EANF;EAQA;IACE,OAAA;EANF;AACF","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n\n.sidebar {\n  background-image: url(\"./background.png\");\n  background-position: center;\n  background-repeat: no-repeat;\n  border-top-right-radius: 12px;\n  border-bottom-right-radius: 12px;\n\n  max-width: 280px;\n  animation: sidebarFocus .5s linear;\n  animation-timing-function: linear;\n\n  -webkit-animation-timing-function: linear; /* Chrome, Safari, Opera */\n\n  -webkit-animation: sidebarFocus .5s ease forwards;\n  -moz-animation: sidebarFocus 1s ease forwards;\n  -o-animation: sidebarFocus 1s ease forwards;\n  left: -50%;\n  z-index: 999;\n}\n\n\n@keyframes sidebarFocus {\n  from {\n    left: -50%;\n  }\n  to {\n    left: 0;\n  }\n}\n\n@-webkit-keyframes sidebarFocus {\n  from {\n    left: -50%;\n  }\n  to {\n    left: 0;\n  }\n}\n\n@-moz-keyframes sidebarFocus {\n  from {\n    left: -50%;\n  }\n  to {\n    left: 0;\n  }\n}\n\n@-o-keyframes sidebarFocus {\n  from {\n    left: -50%;\n  }\n  to {\n    left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `styles_sidebar__HslLt`,
	"sidebarFocus": `styles_sidebarFocus__nu-Xw`
};
export default ___CSS_LOADER_EXPORT___;
