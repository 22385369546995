// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__pyHux {
  min-width: 320px;
}

.styles_description__lOZLM {
  color: var(--black, #313333);
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  max-width: 320px;
}

.styles_cancel__vpRen {
  border: 2px solid #343839;
  color: #313333;
}

.styles_accept__d8U-f {
  border: 2px solid #D23535;
  background-color: #D23535;
  color: #FEFEFE;
}

.styles_button__BWDWE {
  max-width: 232px;
  width: 100%;
  padding: 7px 12px;
  border-radius: 12px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
}

@media screen and (min-width: 744px) {
  .styles_wrapper__pyHux {
    min-width: 483px;
  }
}
@media screen and (min-width: 1024px) {
  .styles_wrapper__pyHux {
    min-width: 574px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/Logout/styles.module.scss","webpack://./src/constants/fonts.scss","webpack://./src/constants/colors.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;AADF;;AAIA;EACE,4BAAA;EACA,kBAAA;EACA,kCCRQ;EDSR,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,aAAA;EACA,uBAAA;EAEA,gBAAA;AAFF;;AAKA;EACE,yBAAA;EACA,cErBM;AFmBR;;AAKA;EACE,yBAAA;EACA,yBAAA;EACA,cExBM;AFsBR;;AAKA;EACE,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,mBAAA;EAEA,kCCnCQ;EDoCR,gBAAA;EACA,eAAA;AAHF;;AAMA;EACE;IACE,gBAAA;EAHF;AACF;AAMA;EACE;IACE,gBAAA;EAJF;AACF","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n.wrapper {\n  min-width: 320px;\n}\n\n.description {\n  color: var(--black, #313333);\n  text-align: center;\n  font-family: $poppins;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px; /* 171.429% */\n  letter-spacing: -0.14px;\n\n  max-width: 320px;\n}\n\n.cancel {\n  border: 2px solid #343839;\n  color: $black;\n}\n\n.accept {\n  border: 2px solid #D23535;\n  background-color: #D23535;\n  color: $white;\n}\n\n.button {\n  max-width: 232px;\n  width: 100%;\n  padding: 7px 12px;\n  border-radius: 12px;\n\n  font-family: $poppins;\n  font-weight: 600;\n  font-size: 16px;\n}\n\n@media screen and (min-width: 744px) {\n  .wrapper {\n    min-width: 483px;\n  }\n}\n\n@media screen and (min-width: 1024px) {\n  .wrapper {\n    min-width: 574px;\n  }\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__pyHux`,
	"description": `styles_description__lOZLM`,
	"cancel": `styles_cancel__vpRen`,
	"accept": `styles_accept__d8U-f`,
	"button": `styles_button__BWDWE`
};
export default ___CSS_LOADER_EXPORT___;
