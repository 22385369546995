import React, { type FC } from 'react'

// Components
import { PaymentItem } from 'pages/Settings/Profile/components/PaymentsList/PaymentItem'

// Types
import type IPayments from 'types/IPayments'
import { useAppSelector } from 'hooks/useRedux'

export const PaymentsList: FC = () => {
  const cards = useAppSelector(state => state.cards.cards)
  return <>
    {(cards && cards?.length > 0) && cards.map((payment: IPayments) =>
      <PaymentItem key={payment.id} payment={payment}/>
    )}
  </>
}
