// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_sidebar__KwgkU {
  max-width: 377px;
}

@media screen and (max-width: 1440px) {
  .styles_sidebar__KwgkU {
    max-width: 297px;
  }
}
@media screen and (max-width: 1024px) {
  .styles_sidebar__KwgkU {
    max-width: 285px;
  }
}
.styles_noScrollBar__DHoFF::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.styles_noScrollBar__DHoFF {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}`, "",{"version":3,"sources":["webpack://./src/layout/Dashboard/styles.module.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;AADF;;AAIA;EACE;IACE,gBAAA;EADF;AACF;AAIA;EACE;IACE,gBAAA;EAFF;AACF;AAKA;EACE,aAAA;AAHF;;AAMA,4CAAA;AACA;EACE,wBAAA,EAAA,gBAAA;EACA,qBAAA,EAAA,YAAA;AAHF","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n.sidebar {\n  max-width: 377px;\n}\n\n@media screen and (max-width: 1440px) {\n  .sidebar {\n    max-width: 297px;\n  }\n}\n\n@media screen and (max-width: 1024px) {\n  .sidebar {\n    max-width: 285px;\n  }\n}\n\n.noScrollBar::-webkit-scrollbar {\n  display: none;\n}\n\n/* Hide scrollbar for IE, Edge and Firefox */\n.noScrollBar {\n  -ms-overflow-style: none;  /* IE and Edge */\n  scrollbar-width: none;  /* Firefox */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `styles_sidebar__KwgkU`,
	"noScrollBar": `styles_noScrollBar__DHoFF`
};
export default ___CSS_LOADER_EXPORT___;
