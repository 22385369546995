// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__wMHre {
  width: 100%;
  height: 52px;
  position: relative;
  display: flex;
  align-items: center;
}

.styles_input__vbVO7 {
  width: 100%;
  height: 52px;
  background-color: #F3F5F7;
  border-radius: 12px;
  padding: 0 52px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #313333;
}
.styles_inputHasError__dTtEy {
  border: 1px solid #D23535;
  box-shadow: 0 0 0 3px rgba(239, 47, 50, 0.2);
}
.styles_input__vbVO7:is(:focus, :active, ::placeholder) {
  outline: none;
  color: black;
}

.styles_leftIconWrapper__juFF\\+ {
  position: absolute;
  left: 17px;
}

.styles_rightIconWrapper__ukFJ9 {
  position: absolute;
  right: 17px;
}

@media screen and (max-width: 568px) {
  .styles_wrapper__wMHre {
    height: 44px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/InputWithIcon/styles.module.scss","webpack://./src/constants/fonts.scss","webpack://./src/constants/colors.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,YAAA;EAEA,kBAAA;EACA,aAAA;EACA,mBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EAEA,gCCfM;EDgBN,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cEtBM;AFmBR;AAKE;EACE,yBAAA;EACA,4CAAA;AAHJ;AAME;EACE,aAAA;EAEA,YAAA;AALJ;;AASA;EACE,kBAAA;EACA,UAAA;AANF;;AASA;EACE,kBAAA;EACA,WAAA;AANF;;AASA;EACE;IACE,YAAA;EANF;AACF","sourcesContent":["@import 'constants/colors.scss';\n@import 'constants/fonts.scss';\n\n.wrapper {\n  width: 100%;\n  height: 52px;\n\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n\n.input {\n  width: 100%;\n  height: 52px;\n  background-color: #F3F5F7;\n  border-radius: 12px;\n  padding: 0 52px;\n\n  font-family: $inter;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 24px;\n  color: $black;\n\n  &HasError {\n    border: 1px solid #D23535;\n    box-shadow: 0 0 0 3px rgba(239, 47, 50, 0.2);\n  }\n\n  &:is(:focus, :active, ::placeholder) {\n    outline: none;\n\n    color: black;\n  }\n}\n\n.leftIconWrapper {\n  position: absolute;\n  left: 17px;\n}\n\n.rightIconWrapper {\n  position: absolute;\n  right: 17px;\n}\n\n@media screen and (max-width: 568px) {\n  .wrapper {\n    height: 44px;\n  }\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__wMHre`,
	"input": `styles_input__vbVO7`,
	"inputHasError": `styles_inputHasError__dTtEy`,
	"leftIconWrapper": `styles_leftIconWrapper__juFF+`,
	"rightIconWrapper": `styles_rightIconWrapper__ukFJ9`
};
export default ___CSS_LOADER_EXPORT___;
