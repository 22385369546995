// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_noScrollBar__djGQT::-webkit-scrollbar {
  display: none;
}

.styles_noScrollBar__djGQT {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 50dvh;
}

.styles_empty__nfGbn {
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #FEFEFE;
}

@media screen and (max-width: 1440px) {
  .styles_noScrollBar__djGQT {
    height: 45dvh;
  }
}
@media screen and (max-width: 1024px) {
  .styles_noScrollBar__djGQT {
    height: 60dvh;
  }
}
@media screen and (max-width: 768px) {
  .styles_noScrollBar__djGQT {
    height: 55dvh;
  }
}
@media screen and (max-width: 568px) {
  .styles_noScrollBar__djGQT {
    height: 50dvh;
  }
}
@media screen and (max-height: 800px) {
  .styles_noScrollBar__djGQT {
    height: 45dvh;
  }
}`, "",{"version":3,"sources":["webpack://./src/layout/Dashboard/components/Sidebar/ChatsList/styles.module.scss","webpack://./src/constants/fonts.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;AAAF;;AAGA;EACE,wBAAA;EACA,qBAAA;EAEA,aAAA;AADF;;AAIA;EACE,WAAA;EACA,kCCbQ;EDcR,eAAA;EACA,gBAAA;EACA,cAAA;AADF;;AAKA;EACE;IACE,aAAA;EAFF;AACF;AAKA;EACE;IACE,aAAA;EAHF;AACF;AAMA;EACE;IACE,aAAA;EAJF;AACF;AAOA;EACE;IACE,aAAA;EALF;AACF;AAQA;EACE;IACE,aAAA;EANF;AACF","sourcesContent":["@import 'constants/fonts.scss';\n\n.noScrollBar::-webkit-scrollbar {\n  display: none;\n}\n\n.noScrollBar {\n  -ms-overflow-style: none;\n  scrollbar-width: none;  \n\n  height: 50dvh;\n}\n\n.empty {\n  width: 100%;\n  font-family: $poppins;\n  font-size: 18px;\n  font-weight: 600;\n  color: #FEFEFE;\n}\n\n\n@media screen and (max-width: 1440px) {\n  .noScrollBar {\n    height: 45dvh;\n  }\n}\n\n@media screen and (max-width: 1024px) {\n  .noScrollBar {\n    height: 60dvh;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .noScrollBar {\n    height: 55dvh;\n  }\n}\n\n@media screen and (max-width: 568px) {\n  .noScrollBar {\n    height: 50dvh;\n  }\n}\n\n@media screen and (max-height: 800px) {\n  .noScrollBar {\n    height: 45dvh;\n  }\n}","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noScrollBar": `styles_noScrollBar__djGQT`,
	"empty": `styles_empty__nfGbn`
};
export default ___CSS_LOADER_EXPORT___;
