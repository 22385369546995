import React, { type FC, type FormEvent, useState } from 'react'
import styles from 'pages/Authorization/styles.module.scss'

// NPM
import clsx from 'clsx'

// Components
import { InputWithIcon } from 'components/index'

// Icons
import { CrossBlack, LockOutlined, MessageOutlined, VisibilityActive, VisibilityDisabled } from 'icons/index'

// Types
import type ServerResponseError from 'types/ServerResponseError'

interface Props {
  handleChangeUserCredentials: any
  handleResetEmailField: () => void
  user: { email: string, password: string }
  handleResetPassword: () => void
  loginError: ServerResponseError
}

const AuthorizationContext: FC<Props> = ({
  handleChangeUserCredentials,
  handleResetPassword,
  handleResetEmailField,
  user,
  loginError
}) => {
  const [hasPasswordSecured, setHasPasswordSecured] = useState(true)

  const handleTogglePasswordVisibility = (): void => {
    setHasPasswordSecured(prevState => !prevState)
  }

  const handlePreventFormSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
  }

  return <>
    <form onSubmit={handlePreventFormSubmit}
          className={'flex items-center justify-center w-full h-auto flex-col mt-6 xs:mt-8'}>
      <InputWithIcon maxLength={64} iconLeft={<MessageOutlined/>} isError={loginError?.data?.message?.includes('email')}
                     iconRight={<CrossBlack/>} autoComplete={'email'}
                     placeholder={'Enter your email'}
                     onChange={handleChangeUserCredentials} onRightIconPress={handleResetEmailField} name={'email'}
                     value={user.email}/>
    </form>

    <form onSubmit={(event) => {
      event.preventDefault()
    }} className={'flex items-center justify-center w-full h-auto flex-col mt-4'}>
      <InputWithIcon maxLength={25} iconLeft={<LockOutlined/>} isError={loginError?.data?.message?.includes('password')}
                     iconRight={hasPasswordSecured ? <VisibilityDisabled/> : <VisibilityActive/>}
                     autoComplete={'password'}
                     onRightIconPress={handleTogglePasswordVisibility} placeholder={'Enter your password'}
                     onChange={handleChangeUserCredentials} type={hasPasswordSecured ? 'password' : 'text'}
                     name={'password'} value={user.password}/>

      <button onClick={handleResetPassword}
              className={clsx('flex items-start self-start justify-start flex-col mt-2', styles.action)}>
        Forgot password?
      </button>
    </form>
  </>
}

export default AuthorizationContext
