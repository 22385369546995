import React, { type FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import type IPayments from 'types/IPayments'

import { useChangeDefaultMethodMutation } from 'services/billing'

interface PropsType {
  payment: IPayments
}

export const PaymentItem: FC<PropsType> = ({ payment }) => {
  const [changeDefaultMethod] = useChangeDefaultMethodMutation()
  const handleChangeDefault = async (): Promise<void> => {
    await changeDefaultMethod(payment.id)
  }

  return <div className={clsx('w-full flex flex-row px-4 justify-between items-center', styles.wrapper)}>
    <span className={styles.card}>{payment.brand.toUpperCase()}..{payment.last4}</span>
    <span onClick={handleChangeDefault}>{
      payment.default
        ? <span className={styles.default}>Default</span>
        : <button className={styles.makeDefault}>Make default</button>
    }</span>
  </div>
}
