import React, { type FC } from 'react'
import styles from './styles.module.scss'

// NPM
import moment from 'moment'
import { useNavigate } from 'react-router'
import clsx from 'clsx'

// Icons
import { TrashOutlined } from 'icons/index'

// Types
import type IChat from 'types/IChat'

// Hooks
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'

// Redux Slice
import { removeChat } from 'store/chats/slice'

interface Props {
  chat: IChat
}

const ChatItem: FC<Props> = ({ chat }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const loading = useAppSelector(state => state.chats.loading)

  const handleTriggerModal = (): void => {
    dispatch(removeChat({ id: chat.id, state: true }))
  }

  const handleRedirectToChatById = (): void => {
    navigate(`/c/${chat.id}`)
  }

  return <div className={clsx('flex flex-row justify-between items-start', styles.wrapper)}>
    <div style={{ opacity: loading ? 0.25 : 1 }} onClick={handleRedirectToChatById} className={'flex flex-col items-start cursor-pointer'}>
      <p className={styles.chat}>{chat.title}</p>
      <p className={styles.date}>{moment(chat.updatedAt).format('MMMM DD YYYY hh:mm A')}</p>
    </div>

    <div className='cursor-pointer' onClick={handleTriggerModal}>
      <TrashOutlined />
    </div>
  </div>
}

export default ChatItem
