// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__WSuwJ {
  width: 100%;
  padding: 12px 24px;
  height: 52px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border: 2px solid #E7ECEF;
  border-radius: 12px;
  color: #313333;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Authorization/components/GoogleAuthButton/styles.module.scss","webpack://./src/constants/colors.scss","webpack://./src/constants/fonts.scss"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EAEA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EAEA,yBAAA;EACA,mBAAA;EACA,cChBM;EDiBN,kCEhBQ;EFiBR,gBAAA;EACA,eAAA;AAHF","sourcesContent":["@import 'constants/colors.scss';\n@import 'constants/fonts.scss';\n\n.wrapper {\n  width: 100%;\n  padding: 12px 24px;\n  height: 52px;\n  cursor: pointer;\n\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  gap: 16px;\n\n  border: 2px solid #E7ECEF;\n  border-radius: 12px;\n  color: $black;\n  font-family: $poppins;\n  font-weight: 600;\n  font-size: 16px;\n}\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__WSuwJ`
};
export default ___CSS_LOADER_EXPORT___;
