// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_heading__VOebq {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #313333;
}

.styles_subscription__4DWQi {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #313333;
  margin-top: 1.5rem;
}
.styles_subscriptionHighlighted__-flm4 {
  color: #29B473;
}
.styles_subscriptionPlaceholder__kpwai {
  margin-top: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #313333;
}
.styles_subscriptionPrice__9O-4P {
  margin-top: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #313333;
}
.styles_subscriptionRemoveAction__WoP1g {
  margin-top: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.styles_benefits__wzw-h {
  border-top: 1px solid #DEE6EC;
  border-bottom: 1px solid #DEE6EC;
  margin-top: 1.5rem;
  padding: 1.5rem 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/Subscription/styles.module.scss","webpack://./src/constants/fonts.scss","webpack://./src/constants/colors.scss"],"names":[],"mappings":"AAGA;EACE,kCCFQ;EDGR,eAAA;EACA,gBAAA;EACA,cENM;AFKR;;AAIA;EACE,kCCTQ;EDUR,eAAA;EACA,gBAAA;EACA,cEbM;EFcN,kBAAA;AADF;AAGE;EACE,cEfI;AFcR;AAIE;EACE,kBAAA;EACA,kCCrBM;EDsBN,gBAAA;EACA,eAAA;EACA,cEzBI;AFuBR;AAKE;EACE,gBAAA;EACA,kCC7BM;ED8BN,gBAAA;EACA,eAAA;EACA,cEjCI;AF8BR;AAME;EACE,kBAAA;EACA,kCCrCM;EDsCN,gBAAA;EACA,eAAA;AAJJ;;AAQA;EACE,6BAAA;EACA,gCAAA;EACA,kBAAA;EACA,iBAAA;AALF","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n.heading {\n  font-family: $poppins;\n  font-size: 36px;\n  font-weight: 700;\n  color: $black\n}\n\n.subscription {\n  font-family: $poppins;\n  font-size: 20px;\n  font-weight: 700;\n  color: $black;\n  margin-top: 1.5rem;\n\n  &Highlighted {\n    color: $green\n  }\n\n  &Placeholder {\n    margin-top: 1.5rem;\n    font-family: $poppins;\n    font-weight: 500;\n    font-size: 16px;\n    color: $black;\n  }\n\n  &Price {\n    margin-top: 1rem;\n    font-family: $poppins;\n    font-weight: 500;\n    font-size: 20px;\n    color: $black;\n  }\n\n  &RemoveAction {\n    margin-top: 1.5rem;\n    font-family: $poppins;\n    font-weight: 500;\n    font-size: 16px;\n  }\n}\n\n.benefits {\n  border-top: 1px solid #DEE6EC;\n  border-bottom: 1px solid #DEE6EC;\n  margin-top: 1.5rem;\n  padding: 1.5rem 0;\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `styles_heading__VOebq`,
	"subscription": `styles_subscription__4DWQi`,
	"subscriptionHighlighted": `styles_subscriptionHighlighted__-flm4`,
	"subscriptionPlaceholder": `styles_subscriptionPlaceholder__kpwai`,
	"subscriptionPrice": `styles_subscriptionPrice__9O-4P`,
	"subscriptionRemoveAction": `styles_subscriptionRemoveAction__WoP1g`,
	"benefits": `styles_benefits__wzw-h`
};
export default ___CSS_LOADER_EXPORT___;
