import React, { type FC } from 'react'
import styles from './styles.module.scss'

// Components
import { OverlayWrapper } from 'components/index'

// Redux Slices
import { logout } from 'store/user/slice'

// Packages
import clsx from 'clsx'
import Cookies from 'js-cookie'

// Icons
import { CrossBlack } from 'icons/index'

// Hooks
import { useAppDispatch } from 'hooks/useRedux'

interface PropsType {
  handleDisableModal: () => void
}

const LogoutModal: FC<PropsType> = ({ handleDisableModal }) => {
  const dispatch = useAppDispatch()

  const handleLogoutAction = (): void => {
    window.location.href = '/'
    dispatch(logout())
    Cookies.remove('accessToken')
  }

  return <OverlayWrapper handleToggleOverlay={handleDisableModal}>
    <div
      onClick={handleDisableModal}
      className={clsx('flex flex-col gap-6 px-8 pt-10 pb-8 relative md:px-12 items-center bg-white rounded-xl', styles.wrapper)}>
      <div className={'absolute right-2.5 top-2.5'}>
        <CrossBlack />
      </div>
      <p className={styles.description}>Are you sure you want to log out?</p>

      <div className={'w-full flex flex-row items-center justify-between gap-4'}>
        <button onClick={handleDisableModal} className={clsx(styles.button, styles.cancel)}>
          Cancel
        </button>

        <button onClick={handleLogoutAction} className={clsx(styles.button, styles.accept)}>
          Log out
        </button>
      </div>
    </div>
  </OverlayWrapper>
}

export default LogoutModal
