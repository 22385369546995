// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__gfT24 {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  transition: 0.25s ease-in-out;
  top: -96px;
  transform: translateX(60%);
  gap: 16px;
}

.styles_heading__hBlgJ {
  color: #29B473;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 32px */
  letter-spacing: 0.082px;
}

.styles_active__2NUS3 {
  transition: 0.25s ease-in-out;
  top: 10px;
}

@media screen and (max-width: 1024px) {
  .styles_wrapper__gfT24 {
    transform: translateX(25%);
  }
}
@media screen and (max-width: 768px) {
  .styles_wrapper__gfT24 {
    transform: translateX(0%);
  }
  .styles_heading__hBlgJ {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/Profile/components/Popup/styles.module.scss","webpack://./src/constants/colors.scss","webpack://./src/constants/fonts.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,6BAAA;EACA,UAAA;EACA,0BAAA;EACA,SAAA;AADF;;AAIA;EACE,cCZM;EDaN,kCEdQ;EFeR,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,uBAAA;AADF;;AAIA;EACE,6BAAA;EACA,SAAA;AADF;;AAIA;EACE;IACE,0BAAA;EADF;AACF;AAIA;EACE;IACE,yBAAA;EAFF;EAKA;IACE,eAAA;EAHF;AACF","sourcesContent":["@import 'constants/colors.scss';\n@import 'constants/fonts.scss';\n\n.wrapper {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  position: fixed;\n  transition: .25s ease-in-out;\n  top: -96px;\n  transform: translateX(60%);\n  gap: 16px;\n}\n\n.heading {\n  color: $green;\n  font-family: $poppins;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 160%; /* 32px */\n  letter-spacing: 0.082px;\n}\n\n.active {\n  transition: .25s ease-in-out;\n  top: 10px;\n}\n\n@media screen and (max-width: 1024px) {\n  .wrapper {\n    transform: translateX(25%);\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .wrapper {\n    transform: translateX(0%);\n  }\n\n  .heading {\n    font-size: 16px;\n  }\n}\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__gfT24`,
	"heading": `styles_heading__hBlgJ`,
	"active": `styles_active__2NUS3`
};
export default ___CSS_LOADER_EXPORT___;
