import React, { type FC, type PropsWithChildren } from 'react'
import styles from './styles.module.scss'

// Components
import { AdvertisementBanner } from 'components/index'
import clsx from 'clsx'

const Container: FC<PropsWithChildren> = ({ children }) => {
  return <div className={clsx('w-full flex flex-col sm:flex-row', styles.wrapper)}>
      <div className={clsx('w-full', styles.sidebar)}>
          <AdvertisementBanner />
      </div>

      <div className={clsx('w-full flex flex-col justify-center items-center px-6 pt-6 pb-12', styles.body)}>
          {children}
      </div>
  </div>
}

export default Container
