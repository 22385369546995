import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

// Services
import { UserService } from 'services/user'

// Slices
import { setUser } from 'store/user/slice'

// Types
import type IPayments from 'types/IPayments'
import { type BaseQueryFn, type FetchArgs } from '@reduxjs/toolkit/dist/query/react'
import type ServerErrorResponse from 'types/ServerResponseError'
import { type IUser } from 'types/IUser'

const { REACT_APP_BASE_URI } = process.env

export const BillingService = createApi({
  reducerPath: 'BillingService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${String(REACT_APP_BASE_URI)}/billings`,
    prepareHeaders: (headers) => {
      const token: string | undefined = Cookies.get('accessToken')

      if (token != null) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    }
  }) as BaseQueryFn<string | FetchArgs, unknown, ServerErrorResponse>,
  tagTypes: ['Card', 'Subscription'],
  endpoints: (builder) => ({
    createSubscription: builder.mutation<IUser, string | undefined>({
      query: (paymentMethodId) => ({
        url: '/createSubscription',
        method: 'POST',
        body: { paymentMethodId }
      }),
      async onQueryStarted (args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          const { data } = await queryFulfilled
          await dispatch(UserService.endpoints.getUser.initiate(null))
          dispatch(setUser({ user: data }))
        } catch (error) {}
      },
      invalidatesTags: ['Subscription']
    }),
    attachPayment: builder.mutation<null, string | undefined>({
      query: (paymentMethodId) => ({
        url: '/attachPaymentMethod',
        method: 'POST',
        body: { paymentMethodId }
      }),
      invalidatesTags: ['Card']
    }),
    getCards: builder.query({
      query: () => ({
        url: '/retrieveCards',
        method: 'GET'
      }),
      transformResponse: (result: IPayments[], meta) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return result.sort((a, b) => b.default - a.default)
      },
      providesTags: ['Card']
    }),
    getSubscriptions: builder.query({
      query: () => ({
        url: '/retrieveSubscription',
        method: 'GET'
      }),
      providesTags: ['Subscription']
    }),
    cancelSubscription: builder.mutation({
      query: () => ({
        url: '/cancelSubscription',
        method: 'DELETE'
      }),
      invalidatesTags: ['Subscription']
    }),
    changeDefaultMethod: builder.mutation<null, string | undefined>({
      query: (paymentMethodId) => ({
        url: '/changeDefaultPaymentMethod',
        method: 'PATCH',
        body: { paymentMethodId }
      }),
      invalidatesTags: ['Card']
    })
  })
})

export const {
  useCreateSubscriptionMutation,
  useAttachPaymentMutation,
  useGetSubscriptionsQuery,
  useGetCardsQuery,
  useCancelSubscriptionMutation,
  useChangeDefaultMethodMutation
} = BillingService
