import React, { type FC } from 'react'
import styles from './styles.module.scss'
import { SVGr } from 'components'
import { NavLink } from 'react-router-dom'

interface PropsType {
  route: {
    path: string
    title: string
    icon: {
      focused: any
      unfocused: any
    }
  }
}

enum RouteColor {
  FOCUSED = '#141718',
  UNFOCUSED = '#BABABA'
}

export const SidebarLink: FC<PropsType> = ({ route }) => {
  return <NavLink to={route.path}
      className={'flex flex-row gap-2.5 items-center'}>
        {window.location.pathname === route.path && <div className={styles.indicator}/>}
        <SVGr Icon={route.path === window.location.pathname ? route.icon.focused : route.icon.unfocused} />
        <span style={{ color: route.path === window.location.pathname ? RouteColor.FOCUSED : RouteColor.UNFOCUSED }}
              className={styles.route}>{route.title}</span>
    </NavLink>
}
