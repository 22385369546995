import React, { type FC } from 'react'
import { BenefitItem } from 'pages/Settings/Subscription/BenefitsList/BenefitItem'

const benefits = [
  'lorem', 'lorem', 'lorem'
]

export const BenefitsList: FC = () => {
  return <ul className={'flex flex-col gap-4'}>
    {benefits.map((benefit, idx) => <BenefitItem key={idx} benefit={benefit} />)}
  </ul>
}
