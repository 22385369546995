import React, { type FC, type FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'

// NPM
import clsx from 'clsx'
import { useNavigate } from 'react-router'

// Layout
import { Container } from 'layout/index'

// Icons
import { CameraSolid, CrossBlack, LockSolid } from 'icons/index'

// Components
import { Button, InputWithIcon } from 'components/index'

// Services
import { useUpdateUserMutation } from 'services/user'
import { uploadImage } from 'utils/uploadImage'

const ProfileSetup: FC = () => {
  const navigate = useNavigate()
  const [image, setImage] = useState<string | null>(null)
  const [imageSpecs, setImageSpecs] = useState<Blob | undefined>(undefined)
  const [hasImageUploaded, setHasImageUploaded] = useState<boolean | null>(null)
  const [userInformation, setUserInformation] = useState({
    fullname: ''
  })

  const [updateUser, { isSuccess, isLoading }] = useUpdateUserMutation()

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleFileSelect = (): void => {
    fileInputRef?.current?.click()
  }

  const handleChangeUserCredentials = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUserInformation({ ...userInformation, [e.target.name]: e.target.value })
  }

  const handleCreateUser = async (): Promise<void> => {
    await updateUser({ fullname: userInformation.fullname })
    if (imageSpecs !== undefined) {
      setHasImageUploaded(false)
      await uploadImage(imageSpecs, imageSpecs.type).finally(() => {
        setHasImageUploaded(true)
      })
    }
  }

  useEffect(() => {
    if (isSuccess || (hasImageUploaded === true)) {
      navigate('/billing')
    }
  }, [isLoading, hasImageUploaded])

  const getUsernameValidation = useCallback(() => {
    let isDisabled = true

    isDisabled = userInformation.fullname.length < 6

    return isDisabled
  }, [userInformation.fullname])

  const handleUploadImage = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (event.target.files !== null) {
      setImageSpecs(event.target.files?.[0])
      setImage(URL.createObjectURL(event.target.files?.[0]))
    }
  }

  const handlePreventDefaultForm = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
  }

  const handleResetNameField = (): void => {
    setUserInformation({
      ...userInformation,
      fullname: ''
    })
  }

  return <Container>
        <div className={clsx('flex items-center justify-center w-full h-auto flex-col', styles.wrapper)}>
            <h1 className={styles.heading}>You’re almost done!</h1>

            <div className={'flex flex-col justify-center items-center mt-6 sm:mt-8'}>
                <div onClick={handleFileSelect} className={styles.uploadField}>
                    {image !== null && <img className={styles.image} src={image} alt={'user image'} />}
                    <input ref={fileInputRef}
                           onChange={handleUploadImage}
                           type={'file'} style={{ display: 'none' }}
                           className={styles.uploadField}
                           accept={'.jpg, .jpeg, .png, .webp, .gif, .bmp'}
                    />

                    <div className={styles.uploadFieldIcon}>
                        <CameraSolid/>
                    </div>
                </div>
                <p className={clsx('mt-2.5', styles.subheading)}>Add your picture</p>
                <p className={styles.subheading}>(optional)</p>
            </div>

            <form onSubmit={handlePreventDefaultForm}
                  className={'flex items-center justify-center w-full h-auto flex-col mt-6 sm:mt-8'}>
                <InputWithIcon iconLeft={<LockSolid/>}
                               placeholder={'Enter your full name'}
                               onChange={handleChangeUserCredentials}
                               name={'fullname'}
                               iconRight={<CrossBlack />}
                               onRightIconPress={handleResetNameField}
                               value={userInformation.fullname} />
            </form>
            <div className={'w-full mt-36 sm:mt-8'}>
                <Button disabled={getUsernameValidation() || isLoading || (hasImageUploaded === false)} isLoading={isLoading || (hasImageUploaded === false)} onClick={handleCreateUser}
                        title={'Create account'}/>
            </div>
        </div>
    </Container>
}

export default ProfileSetup
