import React, { type FC, useCallback, useState } from 'react'

import styles from './styles.module.scss'

// Component
import Layout from 'pages/Settings/layout'
import { useAppSelector } from 'hooks/useRedux'
import { BenefitsList } from 'pages/Settings/Subscription/BenefitsList'
import { CancelSubscription, RenewSubscription } from 'components/Modals'

enum SUBSCRIPTION_STATUS {
  CANCELED = 'Canceled',
  TRIALING = 'Free Trial',
  PAID = 'NexusPRO',
}

enum STATUS_COLOR {
  SUBSCRIBED = '#29B473',
  NOT_SUBSCRIBED = '#D23535'
}

const SubscriptionSettings: FC = () => {
  const subscription = useAppSelector(state => state.cards.subscription)

  const [hasCancelSubscriptionModalActive, setHasCancelSubscriptionModalActive] = useState(false)
  const [hasRenewSubscriptionModalActive, setHasSubscriptionModalActive] = useState(false)

  const handleToggleRenewSubscription = async (): Promise<void> => {
    setHasSubscriptionModalActive(prevState => !prevState)
  }

  const handleToggleCancelSubscriptionModal = (): void => {
    setHasCancelSubscriptionModalActive(prevState => !prevState)
  }

  const getSubscriptionStatus = useCallback(() => {
    return subscription?.status === 'trialing'
      ? SUBSCRIPTION_STATUS.TRIALING
      : subscription?.status === 'canceled'
        ? SUBSCRIPTION_STATUS.CANCELED
        : SUBSCRIPTION_STATUS.PAID
  }, [subscription?.status])

  return <Layout>
    <h1 className={styles.heading}>Edit profile</h1>

    <h2 className={styles.subscription}>Your current plan:
      <span style={{
        color: subscription?.status === 'canceled' ? STATUS_COLOR.NOT_SUBSCRIBED : STATUS_COLOR.SUBSCRIBED
      }}> {getSubscriptionStatus()}</span>
    </h2>

    <div className={styles.benefits}>
      <BenefitsList/>

      <p className={styles.subscriptionPlaceholder}>Subscription cost</p>

      <p className={styles.subscriptionPrice}>${subscription?.totalPrice} USD/month</p>
    </div>

    <button
      onClick={subscription?.status === 'canceled' ? handleToggleRenewSubscription : handleToggleCancelSubscriptionModal}
      className={styles.subscriptionRemoveAction}
      style={{ color: subscription?.status === 'canceled' ? '#29B473' : '#D23535' }}
    >
      {subscription?.status === 'canceled' ? 'Renew' : 'Unsubscribe'}
    </button>

    {hasCancelSubscriptionModalActive && <CancelSubscription handleToggleModal={handleToggleCancelSubscriptionModal}/>}
    {hasRenewSubscriptionModalActive && <RenewSubscription handleToggleModal={handleToggleRenewSubscription} />}
  </Layout>
}

export default SubscriptionSettings
