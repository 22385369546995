import React, { type ButtonHTMLAttributes, type FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { GoogleAuth } from 'icons/index'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick: () => void
}

const GoogleAuthButton: FC<Props> = ({ onClick }) => {
  return <button onClick={onClick} className={clsx(styles.wrapper)}>
    <GoogleAuth/>
    Continue with Google
  </button>
}

export default GoogleAuthButton
