import React, { type FC, type PropsWithChildren } from 'react'
import styles from './styles.module.scss'

import clsx from 'clsx'

// Types
import type IMessage from 'types/IMessage'
import Sidebar from 'layout/Dashboard/components/Sidebar'

// Hooks
import useMediaQuery from 'hooks/useMediaQuery'
import { useAppSelector } from 'hooks/useRedux'
import { useGetUserQuery } from 'services/user'
import { useGetCardsQuery, useGetSubscriptionsQuery } from 'services/billing'
import { useGetChatItemQuery } from 'services/chat'
import { useParams } from 'react-router'

interface Props {
  messages?: IMessage[]
  createMessage?: () => void
  changeMessage?: (e: React.ChangeEvent<HTMLInputElement>) => void
  message?: string
}

const Dashboard: FC<PropsWithChildren<Props>> = ({ children }) => {
  const params = useParams()
  const user = useAppSelector(state => state.user.user)
  const cards = useAppSelector(state => state.cards.cards)
  const subscription = useAppSelector(state => state.cards.subscription)

  useGetUserQuery(null, {
    pollingInterval: 5000,
    skip: user !== null,
    refetchOnMountOrArgChange: false,
    refetchOnReconnect: true
  })

  useGetSubscriptionsQuery(null, {
    pollingInterval: 5000,
    skip: subscription !== null || !user,
    refetchOnMountOrArgChange: false,
    refetchOnReconnect: true
  })

  useGetCardsQuery(null, {
    pollingInterval: 5000,
    skip: cards !== null || !user,
    refetchOnMountOrArgChange: false,
    refetchOnReconnect: true
  })

  useGetChatItemQuery(params.id, {
    skip: params.id === undefined || !user,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    refetchOnFocus: true
  })

  const isTablet = useMediaQuery('(min-width: 744px)')

  return <div className={'w-full flex flex-row relative'}>
    {isTablet && <div className={clsx('w-full', styles.sidebar)}>
      <Sidebar/>
    </div>}

    <div className={'w-full h-full md:h-screen flex flex-col px-6 sm:px-6 xl:px-10'}>
      {children}
    </div>
  </div>
}

export default Dashboard
