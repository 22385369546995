import React, { type FC } from 'react'

interface PropsType {
  Icon: any
}

const SVGr: FC<PropsType> = ({ Icon }) => {
  return <>
        <Icon />
    </>
}

export default SVGr
