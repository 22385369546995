import React, { type FC, type PropsWithChildren } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from 'hooks/useRedux'

export const NonProtectedRoute: FC<PropsWithChildren> = ({ children }) => {
  const user = useAppSelector(state => state.user.user)

  if (!user) {
    return children
  }

  if (user.fullname !== null && user.stripeCustomerId !== null) {
    return <Navigate to={'/chats'} />
  }

  return children
}
