// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__NYvkj {
  max-width: 722px;
}

.styles_heading__C3-QK {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 56px;
  font-size: 44px;
  color: #313333;
}

.styles_subheading__9p4BS {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
  color: #BABABA;
}

@media screen and (max-width: 1920px) {
  .styles_wrapper__NYvkj {
    max-width: 722px;
  }
}
@media screen and (max-width: 1024px) {
  .styles_wrapper__NYvkj {
    max-width: 495px;
  }
}
@media screen and (max-width: 1440px) {
  .styles_wrapper__NYvkj {
    max-width: 521px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/ResetPassword/styles.module.scss","webpack://./src/constants/fonts.scss","webpack://./src/constants/colors.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;AADF;;AAIA;EACE,kCCNQ;EDOR,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,cEXM;AFUR;;AAIA;EACE,kCCdQ;EDeR,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;AADF;;AAIA;EACE;IACE,gBAAA;EADF;AACF;AAIA;EACE;IACE,gBAAA;EAFF;AACF;AAKA;EACE;IACE,gBAAA;EAHF;AACF","sourcesContent":["@import 'constants/colors.scss';\n@import 'constants/fonts.scss';\n\n.wrapper {\n  max-width: 722px;\n}\n\n.heading {\n  font-family: $poppins;\n  font-weight: 700;\n  line-height: 56px;\n  font-size: 44px;\n  color: $black;\n}\n\n.subheading {\n  font-family: $poppins;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: -0.16px;\n  color: #BABABA;\n}\n\n@media screen and (max-width: 1920px) {\n  .wrapper {\n    max-width: 722px;\n  }\n}\n\n@media screen and (max-width: 1024px) {\n  .wrapper {\n    max-width: 495px;\n  }\n}\n\n@media screen and (max-width: 1440px) {\n  .wrapper {\n    max-width: 521px;\n  }\n}\n\n\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__NYvkj`,
	"heading": `styles_heading__C3-QK`,
	"subheading": `styles_subheading__9p4BS`
};
export default ___CSS_LOADER_EXPORT___;
