import React, { type FC, type PropsWithChildren } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { ArrowLeft } from 'icons/index'
import Sidebar from 'pages/Settings/layout/components/Sidebar'
import { useNavigate } from 'react-router'

// Components

// Icons

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()

  const handleNavigateBack = (): void => { navigate('/chats') }

  return <div className={'w-full h-full flex px-4 py-14 flex-col md:flex-row'}>
    <div className={clsx('flex w-full flex-col gap-4', styles.sidebar)}>
      <div className={'md:mt-4'} onClick={handleNavigateBack}>
        <ArrowLeft />
      </div>

      <div className={'flex flex-row gap-6 md:mt-8 md:flex-col'}>
        <Sidebar />
      </div>
    </div>

    <div className={'w-full mt-4 md:mt-0'}>
      {children}
    </div>

  </div>
}

export default Layout
