import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type ChatItemsState } from './type'
import type IChatItem from 'types/IChatItem'
import { ChatService } from 'services/chat'

const initialState: ChatItemsState = {
  items: null,
  loading: false
}

const chatItemsSlice = createSlice({
  name: 'chatItems',
  initialState,
  reducers: {
    setChatItems: (state, action: PayloadAction<{ items: IChatItem[] | null }>) => {
      state.items = action.payload.items
    },
    resetChatItems: (state) => {
      state.items = null
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      ChatService.endpoints.getChatItem.matchFulfilled,
      (state, { payload }) => {
        state.items = payload
        state.loading = false
      }
    )
    builder.addMatcher(
      ChatService.endpoints.createChat.matchPending,
      (state) => {
        state.loading = true
      }
    )
    builder.addMatcher(
      ChatService.endpoints.getChatItem.matchPending,
      (state) => {
        state.loading = true
      }
    )
    builder.addMatcher(
      ChatService.endpoints.getChatItem.matchRejected,
      (state) => {
        state.loading = false
      }
    )
  }
})

export const {
  setChatItems,
  resetChatItems
} = chatItemsSlice.actions
export default chatItemsSlice.reducer
