import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

// Reducers
import userReducer from './user/slice'
import paymentsReducer from './payments/slice'
import chatsReducer from './chats/slice'
import chatItemsReducer from './chat-items/slice'

import { UserService } from 'services/user'
import { VerificationService } from 'services/verify'
import { AuthService } from 'services/auth'
import { BillingService } from 'services/billing'
import { ChatService } from 'services/chat'

export const store = configureStore({
  reducer: {
    [UserService.reducerPath]: UserService.reducer,
    [VerificationService.reducerPath]: VerificationService.reducer,
    [AuthService.reducerPath]: AuthService.reducer,
    [BillingService.reducerPath]: BillingService.reducer,
    [ChatService.reducerPath]: ChatService.reducer,
    user: userReducer,
    cards: paymentsReducer,
    chats: chatsReducer,
    chatItems: chatItemsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      UserService.middleware,
      VerificationService.middleware,
      AuthService.middleware,
      BillingService.middleware,
      ChatService.middleware
    ])
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
