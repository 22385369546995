// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__OQNFv {
  height: 44px;
  background-color: #F3F5F7;
  border-radius: 12px;
}

.styles_card__3Xy8L {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #BABABA;
}

.styles_default__dqIGz {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #BABABA;
}

.styles_makeDefault__yzpwU {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #29B473;
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/Profile/components/PaymentsList/PaymentItem/styles.module.scss","webpack://./src/constants/fonts.scss","webpack://./src/constants/colors.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,yBAAA;EACA,mBAAA;AADF;;AAIA;EACE,kCCRQ;EDSR,gBAAA;EACA,eAAA;EACA,cAAA;AADF;;AAIA;EACE,kCCfQ;EDgBR,gBAAA;EACA,eAAA;EACA,cAAA;AADF;;AAIA;EACE,kCCtBQ;EDuBR,gBAAA;EACA,eAAA;EACA,cExBM;AFuBR","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n.wrapper {\n  height: 44px;\n  background-color: #F3F5F7;\n  border-radius: 12px;\n}\n\n.card {\n  font-family: $poppins;\n  font-weight: 500;\n  font-size: 14px;\n  color: #BABABA\n}\n\n.default {\n  font-family: $poppins;\n  font-weight: 500;\n  font-size: 14px;\n  color: #BABABA;\n}\n\n.makeDefault {\n  font-family: $poppins;\n  font-weight: 500;\n  font-size: 14px;\n  color: $green;\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__OQNFv`,
	"card": `styles_card__3Xy8L`,
	"default": `styles_default__dqIGz`,
	"makeDefault": `styles_makeDefault__yzpwU`
};
export default ___CSS_LOADER_EXPORT___;
