import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

import 'firebase/auth'
import { getAuth, GoogleAuthProvider } from '@firebase/auth'

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_MEASUREMENT_ID
} = process.env

const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: 'nexus-ai-web.firebaseapp.com',
  projectId: 'nexus-ai-web',
  storageBucket: 'nexus-ai-web.appspot.com',
  messagingSenderId: '901942049307',
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_MEASUREMENT_ID
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
getAnalytics(app)
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider()
