import { type BaseQueryFn, createApi, type FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import Cookies from 'js-cookie'
import type ServerErrorResponse from 'types/ServerResponseError'

import type * as Types from './types'
import type IChatItem from 'types/IChatItem'
import type IChat from 'types/IChat'

const { REACT_APP_BASE_URI } = process.env

export const ChatService = createApi({
  reducerPath: 'ChatService',
  baseQuery: fetchBaseQuery({
    baseUrl: `${String(REACT_APP_BASE_URI)}`,
    prepareHeaders: (headers) => {
      const token: string | undefined = Cookies.get('accessToken')

      if (token != null) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    }
  }) as BaseQueryFn<string | FetchArgs, unknown, ServerErrorResponse>,
  tagTypes: ['Chat', 'ChatItem'],
  endpoints: (builder) => ({
    createChat: builder.mutation<Types.CreateChatResponse, Types.CreateChatRequest>({
      query: (title) => ({
        url: '/chat',
        method: 'POST',
        body: title
      }),
      invalidatesTags: ['Chat']
    }),
    getChats: builder.query({
      query: () => ({
        url: '/chat',
        method: 'GET'
      }),
      transformResponse: (result: IChat[]) => {
        return result.reverse()
      },
      providesTags: ['Chat']
    }),
    deleteChat: builder.mutation<unknown, string>({
      query: (id) => ({
        url: `/chat/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Chat']
    }),
    getChatItem: builder.query<IChatItem[], string | undefined>({
      query: (id) => ({
        url: `/chat-item/${String(id)}`,
        method: 'GET'
      }),
      transformResponse: (result: IChatItem[]) => {
        return result.sort((a, b) => a.order - b.order)
      },
      providesTags: ['ChatItem']
    }),
    createNewMessage: builder.mutation<unknown, string | Types.ChatItemRequest>({
      query: (chat) => ({
        url: '/openai/ask-question',
        method: 'POST',
        body: chat
      }),
      invalidatesTags: ['ChatItem']
    }),
    getMessageAnswer: builder.mutation<any, any>({
      query: (chatId) => ({
        url: '/openai/get-answer',
        method: 'POST',
        body: { chatId }
      }),
      invalidatesTags: ['ChatItem']
    })
  })
})

export const {
  useCreateChatMutation,
  useDeleteChatMutation,
  useGetChatItemQuery,
  useCreateNewMessageMutation,
  useGetMessageAnswerMutation,
  useGetChatsQuery
} = ChatService
