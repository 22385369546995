// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__SljO- {
  max-width: 721px;
}

.styles_action__EhD-x {
  max-width: 528px;
  color: #29B473;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: -0.02em;
}

.styles_divider__w-Fe- {
  max-width: 328px;
  height: 1px;
  background-color: #BABABA;
  width: 100%;
}
.styles_dividerPlaceholder__SMJ-V {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #BABABA;
}

@media screen and (max-width: 1440px) {
  .styles_wrapper__SljO- {
    max-width: 521px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Authorization/styles.module.scss","webpack://./src/constants/colors.scss","webpack://./src/constants/fonts.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;AADF;;AAIA;EACE,gBAAA;EACA,cCNM;EDON,gCENM;EFON,gBAAA;EACA,eAAA;EACA,uBAAA;AADF;;AAIA;EACE,gBAAA;EACA,WAAA;EACA,yBAAA;EACA,WAAA;AADF;AAGE;EACE,gCEnBI;EFoBJ,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;AADJ;;AAKA;EACE;IACE,gBAAA;EAFF;AACF","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n.wrapper {\n  max-width: 721px;\n}\n\n.action {\n  max-width: 528px;\n  color: $green;\n  font-family: $inter;\n  font-weight: 600;\n  font-size: 14px;\n  letter-spacing: -0.02em;\n}\n\n.divider {\n  max-width: 328px;\n  height: 1px;\n  background-color: #BABABA;\n  width: 100%;\n\n  &Placeholder {\n    font-family: $inter;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n    letter-spacing: -0.02em;\n    color: #BABABA;\n  }\n}\n\n@media screen and (max-width: 1440px) {\n  .wrapper {\n    max-width: 521px;\n  }\n}\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__SljO-`,
	"action": `styles_action__EhD-x`,
	"divider": `styles_divider__w-Fe-`,
	"dividerPlaceholder": `styles_dividerPlaceholder__SMJ-V`
};
export default ___CSS_LOADER_EXPORT___;
