export { ReactComponent as AppLogo } from './app-logo-main.svg'
export { ReactComponent as AppLogoWhite } from './app-logo-white.svg'
export { ReactComponent as MessageOutlined } from './message-outlined.svg'
export { ReactComponent as CrossBlack } from './cross-black.svg'
export { ReactComponent as LockOutlined } from './lock-outlined.svg'
export { ReactComponent as VisibilityActive } from './visibility-visible.svg'
export { ReactComponent as VisibilityDisabled } from './visibility-disabled.svg'
export { ReactComponent as GoogleAuth } from './google.svg'
export { ReactComponent as MessageSolid } from './message-solid.svg'
export { ReactComponent as LockSolid } from './lock-solid.svg'
export { ReactComponent as CameraSolid } from './camera.svg'
export { ReactComponent as RefreshSolid } from './refresh.svg'
export { ReactComponent as TrashOutlined } from './trash.svg'
export { ReactComponent as SettingsOutlined } from './settings-outlined.svg'
export { ReactComponent as RecordingSolid } from './recording.svg'
export { ReactComponent as Loader } from './loader.svg'
export { ReactComponent as LoaderGreen } from './loader-green.svg'
export { ReactComponent as StarOutlined } from './star-outlined.svg'
export { ReactComponent as ChatOutlined } from './chat-outlined.svg'
export { ReactComponent as SendOutlined } from './send-outlined.svg'
export { ReactComponent as ProfileOutlined } from './profile-outlined.svg'
export { ReactComponent as ArrowLeft } from './arrow-left.svg'
export { ReactComponent as ProfileSidebarFocused } from './profile-sidebar-focused.svg'
export { ReactComponent as ProfileSidebarUnfocused } from './profile-sidebar-unfocused.svg'
export { ReactComponent as StarSidebarFocused } from './star-sidebar-focused.svg'
export { ReactComponent as StarSidebarUnfocused } from './star-sidebar-unfocused.svg'
export { ReactComponent as MenuIcon } from './menu-icon.svg'
export { ReactComponent as CheckmarkCircleOutlined } from './checkmark-circle-outlined.svg'
