// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_sidebar__6sx5E {
  max-width: 525px;
}

@media screen and (max-width: 1440px) {
  .styles_sidebar__6sx5E {
    max-width: 405px;
  }
}
@media screen and (max-width: 1024px) {
  .styles_sidebar__6sx5E {
    max-width: 282px;
  }
}
@media screen and (max-width: 640px) {
  .styles_sidebar__6sx5E {
    max-width: 640px;
    height: 308px !important;
    overflow: hidden;
    border-radius: 0 0 12px 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/layout/Container/styles.module.scss"],"names":[],"mappings":"AAIA;EACE,gBAAA;AAHF;;AAUA;EACE;IACE,gBAAA;EAPF;AACF;AAUA;EACE;IACE,gBAAA;EARF;AACF;AAWA;EACE;IACE,gBAAA;IACA,wBAAA;IACA,gBAAA;IACA,4BAAA;EATF;AACF","sourcesContent":[".wrapper {\n\n}\n\n.sidebar {\n  max-width: 525px;\n}\n\n.body {\n\n}\n\n@media screen and (max-width: 1440px) {\n  .sidebar {\n    max-width: 405px;\n  }\n}\n\n@media screen and (max-width: 1024px) {\n  .sidebar {\n    max-width: 282px;\n  }\n}\n\n@media screen and (max-width: 640px) {\n  .sidebar {\n    max-width: 640px;\n    height: 308px !important;\n    overflow: hidden;\n    border-radius: 0 0 12px 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `styles_sidebar__6sx5E`
};
export default ___CSS_LOADER_EXPORT___;
