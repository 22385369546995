import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type PaymentState } from './type'
import { BillingService } from 'services/billing'

// Utils

const initialState: PaymentState = {
  cards: null,
  subscription: null
}

const paymentsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    setCards: (state, action: PayloadAction<{ cards: any }>) => {
      state.cards = action.payload.cards
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      BillingService.endpoints.getSubscriptions.matchFulfilled,
      (state, { payload }) => {
        state.subscription = payload
      }
    )
    builder.addMatcher(
      BillingService.endpoints.getCards.matchFulfilled,
      (state, { payload }) => {
        state.cards = payload
      }
    )
  }
})

export const { setCards } = paymentsSlice.actions
export default paymentsSlice.reducer
