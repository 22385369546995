import React, { type FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

// Icons
import { CrossBlack, Loader } from 'icons/index'

// Components
import Overlay from 'components/Overlay'
import { useCancelSubscriptionMutation } from 'services/billing'

interface PropsType {
  handleToggleModal: () => void
}

const CancelSubscription: FC<PropsType> = ({ handleToggleModal }) => {
  const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation()
  const handleCancelSubscription = async (): Promise<void> => {
    await cancelSubscription(null).finally(() => {
      handleToggleModal()
    })
  }

  return <Overlay handleToggleOverlay={handleToggleModal}>
    <div onClick={handleToggleModal} className={clsx('mx-2 max-w-[574px] w-full min-h-[208px] bg-white rounded-xl p-4')}>

      <div className={'flex justify-end items-end'}>
        <CrossBlack/>
      </div>

      <div className={'flex flex-col gap-8 px-1.5 sm:px-8'}>
        <div className={'flex flex-col items-center'}>
          <p className={styles.description}>Are you sure you want to cancel your subscription?</p>
          <p className={styles.description}>You will no longer have access to NexusAI</p>
        </div>

        <div className={'flex flex-row items-center justify-between gap-4'}>
          <button onClick={handleToggleModal} className={clsx(styles.button, styles.cancel)}>
            Cancel
          </button>

          <button onClick={handleCancelSubscription} className={clsx(styles.button, styles.accept)}>
            {isLoading ? <Loader width={20} height={20} /> : 'Unsubscribe'}
          </button>
        </div>
      </div>

    </div>
  </Overlay>
}
export default CancelSubscription
