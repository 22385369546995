import React, { type FC, useCallback, useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'

// NPM
import clsx from 'clsx'

// Components
import Layout from 'pages/Settings/layout'
import { Logout, NewPaymentMethod } from 'components/Modals'
import { Button, InputWithIcon } from 'components/index'
import { PaymentsList } from 'pages/Settings/Profile/components/PaymentsList'

// Icons
import { LockOutlined, ProfileOutlined, VisibilityActive, VisibilityDisabled } from 'icons/index'

// Hooks
import { useAppSelector } from 'hooks/useRedux'
import { useLockedBody } from 'hooks/useLockBodyScroll'

// Images
import { DefaultAvatar } from 'images/index'
import { uploadImage } from 'utils/uploadImage'
import { useUpdateUserMutation } from 'services/user'
import { PopupView } from 'pages/Settings/Profile/components/Popup'

const ProfileSettings: FC = () => {
  const user = useAppSelector(state => state.user.user)
  const fileInputRef = useRef<HTMLInputElement>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setLocked] = useLockedBody(false, 'root')

  // Services
  const [updateUser, { isSuccess: hasUsernameChanged }] = useUpdateUserMutation()

  const [image, setImage] = useState<string | null>(null)
  const [hasNewPaymentModalActive, setHasNewPaymentModalActive] = useState(false)
  const [hasLogoutModalActive, setHasLogoutModalActive] = useState(false)

  const [hasUsernamePopupActive, setHasUsernamePopupActive] = useState(false)
  const [hasPasswordPopupActive, setHasPasswordPopupActive] = useState(false)

  const [hasPasswordSecured, setHasPasswordSecured] = useState({
    current: true,
    repeat: true
  })

  const [userInformation, setUserInformation] = useState({
    avatar: user?.avatar,
    name: user?.fullname ?? '',
    password: '',
    newPassword: '',
    confirmNewPassword: ''
  })
  const handleChangeUserInformation = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUserInformation({
      ...userInformation,
      [e.target.name]: e.target.value
    })
  }

  const handleChangePassword = (): void => {
    setHasPasswordPopupActive(prevState => !prevState)
    console.log('change password')
  }

  const handleToggleCurrentPasswordVisibility = (): void => {
    setHasPasswordSecured({
      ...hasPasswordSecured,
      current: !hasPasswordSecured.current
    })
  }

  const handleToggleRetypedPasswordVisibility = (): void => {
    setHasPasswordSecured({
      ...hasPasswordSecured,
      repeat: !hasPasswordSecured.repeat
    })
  }

  useEffect(() => {
    setLocked(hasNewPaymentModalActive)
  }, [hasNewPaymentModalActive])

  const handleToggleNewPaymentModal = useCallback(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })

    setTimeout(() => {
      setHasNewPaymentModalActive(prevState => !prevState)
    }, 300)
  }, [])

  const handleSubmitEvent = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
  }

  const handleUploadImage = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (event.target.files !== null) {
      setImage(URL.createObjectURL(event.target.files?.[0]))
      await uploadImage(event.target.files?.[0], event.target.files?.[0].type)
    }
  }

  const handleFileSelectVoid = (): void => {
    fileInputRef?.current?.click()
  }

  const handleChangeUsername = async (): Promise<void> => {
    if (user?.fullname.toLowerCase() !== userInformation.name.toLowerCase()) {
      await updateUser({ fullname: userInformation.name })
      // setHasUsernamePopupActive(prevState => !prevState)
    }
  }

  const handleToggleLogoutModal = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    setHasLogoutModalActive(prevState => !prevState)
  }

  useEffect(() => {
    if (hasUsernameChanged) {
      setHasUsernamePopupActive(true)
    }

    const timeout = setTimeout(() => {
      setHasUsernamePopupActive(false)
    }, 3500)

    return () => {
      clearTimeout(timeout)
    }
  }, [hasUsernameChanged])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (hasPasswordPopupActive) {
        setHasPasswordPopupActive(prevState => !prevState)
      }
    }, 3500)

    return () => {
      clearTimeout(timeout)
    }
  }, [hasPasswordPopupActive])

  return <Layout>
    <PopupView active={hasUsernamePopupActive} action={'The name was successfully changed'} />
    <PopupView active={hasPasswordPopupActive} action={'Password was successfully changed'} />
    <h1 className={styles.heading}>Edit profile</h1>

    <div className={clsx('mt-6', styles.avatar)}>
      <img
        src={image !== null ? image : user?.avatar ? user?.avatar : DefaultAvatar}
        className={styles.avatar}
        alt={'user image'}
      />
    </div>

    <div>
      <button onClick={handleFileSelectVoid} className={clsx('mt-4', styles.uploadImageAction)}>Upload new image
      </button>
      <input ref={fileInputRef}
             onChange={handleUploadImage}
             type={'file'} style={{ display: 'none' }}
             className={styles.uploadField}
             accept={'.jpg, .jpeg, .png, .webp, .gif, .bmp'}
      />
    </div>

    <div className={'mt-4'}>
      <form onSubmit={handleSubmitEvent} className={clsx('w-full flex flex-col gap-6 mt-10', styles.form)}>
        <h2 className={styles.formHeading}>Your name</h2>
        <InputWithIcon type={'text'}
                       iconLeft={<ProfileOutlined/>}
                       onChange={handleChangeUserInformation}
                       name={'name'}
                       value={userInformation.name}/>

        <div className={styles.button}>
          <Button title={'Change your name'} bgColor={'black'} disabled={false}
                  onClick={handleChangeUsername}/>
        </div>
      </form>

      <form onSubmit={handleSubmitEvent} className={clsx('w-full flex flex-col gap-6 mt-10 mb-6', styles.form)}>
        <h2 className={styles.formHeading}>Your password</h2>
        <InputWithIcon type={hasPasswordSecured.current ? 'password' : 'text'}
                       autoComplete={'password'}
                       iconRight={hasPasswordSecured.current ? <VisibilityDisabled/> : <VisibilityActive/>}
                       onRightIconPress={handleToggleCurrentPasswordVisibility}
                       iconLeft={<LockOutlined/>}
                       onChange={handleChangeUserInformation}
                       placeholder={'Enter your password'}
                       name={'password'}
                       value={userInformation.password}
        />

        <InputWithIcon type={hasPasswordSecured.repeat ? 'password' : 'text'}
                       autoComplete={'new-password'}
                       iconRight={hasPasswordSecured.repeat ? <VisibilityDisabled/> : <VisibilityActive/>}
                       onRightIconPress={handleToggleRetypedPasswordVisibility}
                       iconLeft={<LockOutlined/>}
                       onChange={handleChangeUserInformation}
                       placeholder={'Create your new password '}
                       name={'newPassword'}
                       value={userInformation.newPassword}
        />

        <InputWithIcon type={hasPasswordSecured.repeat ? 'password' : 'text'}
                       autoComplete={'new-password'}
                       iconLeft={<LockOutlined/>}
                       iconRight={hasPasswordSecured.repeat ? <VisibilityDisabled/> : <VisibilityActive/>}
                       onChange={handleChangeUserInformation}
                       placeholder={'Enter your new password again'}
                       name={'confirmNewPassword'}
                       value={userInformation.confirmNewPassword}
        />
      </form>
      <div className={styles.button}>
        <Button title={'Change password'} bgColor={'black'} disabled={false} onClick={handleChangePassword}/>
      </div>

      <form onSubmit={handleSubmitEvent} className={clsx('w-full flex flex-col gap-6 mt-10 mb-6', styles.form)}>
        <h2 className={styles.formHeading}>Your payment method</h2>
        <PaymentsList/>
      </form>
      <div className={styles.button}>
        <Button title={'Add new payment method'} bgColor={'black'} disabled={false}
                onClick={handleToggleNewPaymentModal}/>
      </div>
    </div>

    <div className="mt-10">
      <button onClick={handleToggleLogoutModal} className={styles.logoutAction}>
        Log out
      </button>
    </div>

    {hasLogoutModalActive && <Logout handleDisableModal={handleToggleLogoutModal}/>}
    {hasNewPaymentModalActive && <NewPaymentMethod handleToggleModal={handleToggleNewPaymentModal}/>}

  </Layout>
}

export default ProfileSettings
