import React, { type FC, useMemo } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

// Icons
import { CrossBlack, Loader } from 'icons/index'

// Components
import Overlay from 'components/Overlay'

// Services
import { useCreateSubscriptionMutation } from 'services/billing'

// Hooks
import { useAppSelector } from 'hooks/useRedux'

interface PropsType {
  handleToggleModal: () => void
}

const RenewSubscription: FC<PropsType> = ({ handleToggleModal }) => {
  const cards = useAppSelector(state => state.cards.cards)

  const [createSubscription, { isLoading }] = useCreateSubscriptionMutation()

  const getDefaultPaymentCard = useMemo(() => {
    const defaultCard = cards?.find(card => card.default)

    return defaultCard?.id
  }, [cards?.length])

  const handleRenewSubscription = async (): Promise<void> => {
    await createSubscription(getDefaultPaymentCard).finally(() => {
      handleToggleModal()
    })
  }

  return <Overlay handleToggleOverlay={handleToggleModal}>
    <div onClick={handleToggleModal} className={clsx('mx-2 max-w-[574px] w-full min-h-[208px] bg-white rounded-xl p-4')}>

      <div className={'flex justify-end items-end'}>
        <CrossBlack/>
      </div>

      <div className={'flex flex-col gap-8 px-1.5 sm:px-8'}>
        <div className={'flex flex-col items-center'}>
          <p className={styles.description}>You will be charged 13.99$ after you renew the subscription</p>
        </div>

        <div className={'flex flex-row items-center justify-between gap-4'}>
          <button onClick={handleToggleModal} className={clsx(styles.button, styles.cancel)}>
            Cancel
          </button>

          <button onClick={handleRenewSubscription} className={clsx(styles.button, styles.accept)}>
            {isLoading ? <Loader width={20} height={20} /> : 'Renew' }
          </button>
        </div>
      </div>

    </div>
  </Overlay>
}
export default RenewSubscription
