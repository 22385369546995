// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__JwY65 {
  left: 0;
  background-color: rgba(20, 23, 24, 0.6);
}`, "",{"version":3,"sources":["webpack://./src/components/Overlay/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,OAAA;EACA,uCAAA;AACF","sourcesContent":[".wrapper {\n  left: 0;\n  background-color: rgba(20, 23, 24, .6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__JwY65`
};
export default ___CSS_LOADER_EXPORT___;
