import React, { type FC, useCallback } from 'react'
import styles from './styles.module.scss'

// NPM
import clsx from 'clsx'
import ReactTyped from 'react-typed'
import moment from 'moment/moment'

// Types
import type IChatMessage from 'types/IChatItem'

// Hooks
import { useAppSelector } from 'hooks/useRedux'

// Images
import { DefaultAvatar } from 'images/index'

interface Props {
  message: IChatMessage
}

const MessageItem: FC<Props> = ({ message }) => {
  const user = useAppSelector(state => state.user.user)
  const messages = useAppSelector(state => state.chatItems)

  const getTypedValidation = useCallback(() => {
    return message.type === 'Response' && message.order === messages.items?.length && moment(message.createdAt).isAfter(moment().subtract(5, 'seconds'))
  }, [messages, message])

  return <div className={clsx('flex flex-row items-center min-w-[120px] max-w-[1440px] p-5 gap-4', styles.wrapper, {
    [styles.wrapperOutlined]: message.type === 'Request'
  })}>
    <img
      className={'w-[40px] h-[40px] rounded-full'}
      src={message.type === 'Request' ? user?.avatar ? user.avatar : DefaultAvatar : DefaultAvatar}
      alt={'avatar'}/>

    {!getTypedValidation() && <span className={styles.message}>{message.data}</span>}
    {getTypedValidation() && <ReactTyped typeSpeed={30} className={styles.message} strings={[message.data]}/>}
  </div>
}

export default MessageItem
