import React, { type FC } from 'react'

interface PropsType {
  benefit: string
}

export const BenefitItem: FC<PropsType> = ({ benefit }) => {
  return <li className={'flex flex-row items-center gap-2.5'}>
    <CircleListItem />

    {benefit}
  </li>
}

const CircleListItem: FC = () => {
  return <div className={'w-2.5 h-2.5 rounded-3xl bg-[#29B473]'}/>
}
