// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__JayB2 {
  cursor: pointer;
  width: 50%;
  margin: 4px;
  height: 40px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.styles_wrapperActive__zV8yV {
  background: #FEFEFE;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.07), inset 0 4px 2px #FFFFFF;
  border-radius: 10px;
  transition: 0.25s ease-in-out;
}
.styles_wrapperValue__r4YqO {
  color: #6C7275;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.styles_wrapperValueActive__9q-uc {
  color: #313333;
}`, "",{"version":3,"sources":["webpack://./src/pages/Authorization/components/TabsWrapper/TabWrapper/styles.module.scss","webpack://./src/constants/colors.scss","webpack://./src/constants/fonts.scss"],"names":[],"mappings":"AAGA;EACE,eAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFF;AAIE;EACE,mBAAA;EACA,kEAAA;EACA,mBAAA;EACA,6BAAA;AAFJ;AAKE;EACE,cCtBG;EDuBH,gCEnBI;EFoBJ,gBAAA;EACA,eAAA;AAHJ;AAKI;EACE,cC3BE;ADwBR","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n.wrapper {\n  cursor: pointer;\n  width: 50%;\n  margin: 4px;\n  height: 40px;\n  border-radius: 12px;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  &Active {\n    background: #FEFEFE;\n    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.07), inset 0 4px 2px #FFFFFF;\n    border-radius: 10px;\n    transition: .25s ease-in-out;\n  }\n\n  &Value {\n    color: $gray;\n    font-family: $inter;\n    font-weight: 500;\n    font-size: 14px;\n\n    &Active {\n      color: $black\n    }\n  }\n}\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__JayB2`,
	"wrapperActive": `styles_wrapperActive__zV8yV`,
	"wrapperValue": `styles_wrapperValue__r4YqO`,
	"wrapperValueActive": `styles_wrapperValueActive__9q-uc`
};
export default ___CSS_LOADER_EXPORT___;
