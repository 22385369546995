import React, { type FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

// Components
import ChatItem from 'layout/Dashboard/components/Sidebar/ChatsList/ChatItem'

// Hooks
import { useAppSelector } from 'hooks/useRedux'

// Types
import type IChat from 'types/IChat'

const ChatsList: FC = () => {
  const chats = useAppSelector(state => state.chats.chats)

  return <div className={clsx('w-full overflow-y-scroll flex flex-col gap-8 bg-transparent mt-14', styles.noScrollBar)}>
    {
      chats?.length
        ? chats?.map((chat: IChat, idx) => <ChatItem key={idx} chat={chat} />)
        : <span className={styles.empty}>No chats history available</span>
    }
  </div>
}

export default ChatsList
