import { type BaseQueryFn, createApi, type FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Types
import type IUpload from 'types/IUpload'
import type * as Types from './types'
import { type IUser } from 'types/IUser'
import type ServerErrorResponse from 'types/ServerResponseError'

// Packages
import Cookies from 'js-cookie'

const { REACT_APP_BASE_URI } = process.env

export const UserService = createApi({
  reducerPath: 'UserService',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_BASE_URI,
    prepareHeaders: (headers) => {
      const token: string | undefined = Cookies.get('accessToken')

      if (token != null) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    }
  }) as BaseQueryFn<string | FetchArgs, unknown, ServerErrorResponse>,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    createUser: builder.mutation<IUser, Types.UserSignupCredentials>({
      query: (user) => ({
        url: '/user',
        method: 'POST',
        body: user
      })
    }),
    updateUser: builder.mutation<IUser, Types.IUpdateUser>({
      query: (user) => ({
        url: '/user/current-user',
        method: 'PATCH',
        body: user
      }),
      invalidatesTags: ['User']
    }),
    generateUploadURI: builder.mutation<IUpload, Types.IUploadPictureRequest>({
      query: (contentType) => ({
        url: '/aws/upload',
        method: 'POST',
        body: contentType
      }),
      invalidatesTags: ['User']
    }),
    getUser: builder.query<IUser, null>({
      query: () => ({
        url: '/auth/current-user',
        method: 'GET'
      }),
      providesTags: ['User']
    })
  })
})

export const {
  useCreateUserMutation,
  useUpdateUserMutation,
  useGenerateUploadURIMutation,
  useGetUserQuery
} = UserService
