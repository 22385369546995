import React, { type ChangeEvent, useCallback, useState } from 'react'
import styles from './styles.module.scss'

// NPM
import clsx from 'clsx'

// Components
import { Container } from 'layout/index'
import { Button, InputWithIcon } from 'components/index'
import { CrossBlack, MessageOutlined } from 'icons/index'

// Schemas
import * as Schemas from 'schemas'
import { useNavigate } from 'react-router-dom'
import { useResendVerificationMutation } from 'services/verify'

// Icons

const ResetPassword: React.FC = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')

  const [resendVerification] = useResendVerificationMutation()

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value)
  }

  const handleResetEmail = (): void => {
    setEmail('')
  }

  const getEmailValidation = useCallback(() => {
    return !Schemas.emailRegex.test(email)
  }, [email])

  const handleSendVerificationCode = async (): Promise<void> => {
    await resendVerification({ email })
    navigate('/verification', {
      state: {
        isResetting: true,
        email
      }
    })
  }

  return <Container>
    <div className={clsx('w-full flex flex-col gap-8 items-center', styles.wrapper)}>
      <h1 className={styles.heading}>Enter your email</h1>

      <p className={styles.subheading}>We will send you a 6-digit code to the email address below</p>

      <InputWithIcon
        iconLeft={<MessageOutlined/>}
        iconRight={<CrossBlack/>}
        onRightIconPress={handleResetEmail}
        placeholder={'Enter your email'}
        onChange={handleChangeEmail}
        name={'email'}
        value={email}
      />

      <Button disabled={getEmailValidation()} onClick={handleSendVerificationCode} title={'Get a digital code'}/>
    </div>
  </Container>
}

export default ResetPassword
