import React, { type FC, type InputHTMLAttributes } from 'react'

import styles from './styles.module.scss'
import clsx from 'clsx'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  onRightIconPress?: () => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  name: string
  value: string | undefined
  placeholder?: string
  type?: 'text' | 'password'
  isError?: boolean
}

const InputWithIcon: FC<Props> = (
  {
    maxLength,
    autoComplete,
    iconLeft,
    iconRight,
    onChange,
    name,
    type = 'text',
    placeholder,
    value,
    onRightIconPress,
    isError
  }, rest) => {
  return <div className={styles.wrapper}>
    {iconLeft !== undefined && <div className={styles.leftIconWrapper}>
      {iconLeft}
    </div>}
    <input {...rest}
           autoComplete={autoComplete}
           maxLength={maxLength}
           name={name}
           type={type}
           placeholder={placeholder}
           value={value}
           onChange={onChange}
           className={clsx(styles.input, {
             [styles.inputHasError]: isError
           })}/>
    {iconRight !== undefined && <div onClick={onRightIconPress} className={styles.rightIconWrapper}>
      {iconRight}
    </div>}
  </div>
}

export default InputWithIcon
