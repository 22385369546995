// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__yVLPN {
  max-width: 721px;
}

.styles_heading__OTdu6 {
  font-size: 36px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #313333;
  line-height: 50px;
}

.styles_image__K628E {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100px;
  z-index: 999;
}

.styles_subheading__vSkbu {
  color: #BABABA;
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.styles_uploadField__ulK6M {
  width: 100px;
  height: 100px;
  border: 2px dashed #BABABA;
  background-color: #FEFEFE;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.styles_uploadFieldIcon__OcykA {
  position: absolute;
}

@media screen and (max-width: 1440px) {
  .styles_wrapper__yVLPN {
    max-width: 521px;
  }
}
@media screen and (max-width: 568px) {
  .styles_heading__OTdu6 {
    font-size: 24px;
  }
  .styles_uploadField__ulK6M, .styles_image__K628E {
    width: 80px;
    height: 80px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/ProfileSetup/styles.module.scss","webpack://./src/constants/fonts.scss","webpack://./src/constants/colors.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;AADF;;AAIA;EACE,eAAA;EACA,kCCPQ;EDQR,gBAAA;EAEA,cEXM;EFYN,iBAAA;AAFF;;AAKA;EACE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,oBAAA;EACA,YAAA;AAFF;;AAKA;EACE,cAAA;EACA,eAAA;EACA,gCCvBM;EDwBN,gBAAA;AAFF;;AAKA;EACE,YAAA;EACA,aAAA;EACA,0BAAA;EACA,yBE/BM;EFgCN,mBAAA;EAGA,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,kBAAA;AALF;AAOE;EACE,kBAAA;AALJ;;AASA;EACE;IACE,gBAAA;EANF;AACF;AASA;EACE;IACE,eAAA;EAPF;EAUA;IACE,WAAA;IACA,YAAA;EARF;AACF","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n.wrapper {\n  max-width: 721px;\n}\n\n.heading {\n  font-size: 36px;\n  font-family: $poppins;\n  font-weight: 700;\n\n  color: $black;\n  line-height: 50px;\n}\n\n.image {\n  width: 100px;\n  height: 100px;\n  object-fit: cover;\n  border-radius: 100px;\n  z-index: 999;\n}\n\n.subheading {\n  color: #BABABA;\n  font-size: 12px;\n  font-family: $inter;\n  font-weight: 400;\n}\n\n.uploadField {\n  width: 100px;\n  height: 100px;\n  border: 2px dashed #BABABA;\n  background-color: $white;\n  border-radius: 100%;\n\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  position: relative;\n\n  &Icon {\n    position: absolute;\n  }\n}\n\n@media screen and (max-width: 1440px) {\n  .wrapper {\n    max-width: 521px;\n  }\n}\n\n@media screen and (max-width: 568px) {\n  .heading {\n    font-size: 24px;\n  }\n\n  .uploadField, .image {\n    width: 80px;\n    height: 80px;\n  }\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__yVLPN`,
	"heading": `styles_heading__OTdu6`,
	"image": `styles_image__K628E`,
	"subheading": `styles_subheading__vSkbu`,
	"uploadField": `styles_uploadField__ulK6M`,
	"uploadFieldIcon": `styles_uploadFieldIcon__OcykA`
};
export default ___CSS_LOADER_EXPORT___;
