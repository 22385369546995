import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'

// NPM
import { signInWithPopup } from '@firebase/auth'
import { useNavigate } from 'react-router'
import { useCookies } from 'react-cookie'
import clsx from 'clsx'

// Layout
import { Container } from 'layout/index'

// Icons
import { AppLogo } from 'icons/index'

// Components
import { Button } from 'components/index'
import TabsWrapper from 'pages/Authorization/components/TabsWrapper'
import GoogleAuthButton from 'pages/Authorization/components/GoogleAuthButton'
import AuthorizationContext from 'pages/Authorization/components/TabsWrapper/TabsContext/Authorization'
import RegistrationContext from 'pages/Authorization/components/TabsWrapper/TabsContext/Registration'

// Hooks
import { useAppDispatch } from 'hooks/useRedux'
import useMediaQuery from 'hooks/useMediaQuery'

// Schemas
import * as Schemas from 'schemas'

// Services
import { useCreateUserMutation, UserService } from 'services/user'
import { useLoginUserMutation, useLoginViaGoogleMutation } from 'services/auth'

// Types
import type ServerErrorResponse from 'types/ServerResponseError'

// lib
import { auth, googleProvider } from 'lib/firebase'

const Authorization: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies(['accessToken'])
  const isMobile = useMediaQuery('(max-width: 400px)')
  const [activeTab, setActiveTab] = useState<string>('Create account')
  const [user, setUser] = useState({
    email: '',
    password: '',
    retype: ''
  })
  // Sign In Service
  const [loginUser, {
    data,
    error: hasLoginUserError,
    isLoading: hasLoginUserLoaded,
    isSuccess: hasSignedIn
  }] = useLoginUserMutation()
  // Sign Up Service
  const [createUser, { isLoading: hasCreatedUserLoaded }] = useCreateUserMutation()
  // Google oAuth
  const [loginViaGoogle, {
    data: googleTokenResponse,
    isLoading: isGoogleAuthLoading,
    isSuccess: hasReturnedGoogleTokens
  }] = useLoginViaGoogleMutation()

  const handleChangeUserCredentials = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    setUser({
      email: '',
      password: '',
      retype: ''
    })
  }, [activeTab])

  const handleResetEmailField = useCallback(() => {
    setUser({
      ...user,
      email: ''
    })
  }, [])

  const handleAuthViaGoogle = async (): Promise<void> => {
    try {
      await signInWithPopup(auth, googleProvider).then(async (response: any) => {
        await loginViaGoogle({ token: response._tokenResponse.oauthAccessToken })
        await dispatch(UserService.endpoints.getUser.initiate(null))
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleAuthorizeUser = async (): Promise<void> => {
    if (activeTab === 'Create account') {
      await createUser({
        email: user.email,
        password: user.password
      })
      navigate('/verification', { state: { email: user.email } })
    } else {
      await loginUser({
        email: user.email,
        password: user.password
      })
    }
  }

  useEffect(() => {
    if (hasSignedIn && data) {
      setCookie('accessToken', data.accessToken)
      navigate('/chats')
    }
  }, [hasSignedIn, data])

  useEffect(() => {
    if (hasReturnedGoogleTokens && googleTokenResponse) {
      setCookie('accessToken', googleTokenResponse.accessToken)
      navigate('/chats')
    }
  }, [hasReturnedGoogleTokens, googleTokenResponse])

  const handleNavigateToResetPassword = (): void => {
    navigate('/reset')
  }

  const getUserValidation = useCallback(() => {
    let isDisabled = true

    switch (activeTab) {
      case 'Create account': {
        isDisabled = !Schemas.emailRegex.test(user.email) || !Schemas.passwordRegex.test(user.password) || user.password !== user.retype
        break
      }
      case 'Sign in': {
        isDisabled = !Schemas.emailRegex.test(user.email) || !Schemas.passwordRegex.test(user.password)
        break
      }
    }

    return isDisabled
  }, [user, activeTab])

  return <Container>
    <div className={clsx('w-full h-[571px]', styles.wrapper)}>
      <figcaption className={'flex justify-center items-center'}>
        <AppLogo width={isMobile ? 160 : 269} height={isMobile ? 40 : 69}/>
      </figcaption>
      <div className={clsx('w-full mt-6 xs:mt-8', styles.wrapper)}>
        <TabsWrapper activeTab={activeTab} setActiveTab={setActiveTab} items={['Create account', 'Sign in']}/>
        {activeTab === 'Create account'
          ? <RegistrationContext handleChangeUserCredentials={handleChangeUserCredentials}
                                 handleResetEmailField={handleResetEmailField}
                                 user={user}
          />
          : <AuthorizationContext handleChangeUserCredentials={handleChangeUserCredentials}
                                  handleResetPassword={handleNavigateToResetPassword}
                                  handleResetEmailField={handleResetEmailField}
                                  loginError={hasLoginUserError as ServerErrorResponse}
                                  user={user}/>}

        <div className={'flex items-center justify-center w-full gap-8 h-auto flex-col mt-8'}>
          <Button disabled={getUserValidation()} onClick={handleAuthorizeUser}
                  isLoading={hasLoginUserLoaded || hasCreatedUserLoaded || isGoogleAuthLoading}
                  title={activeTab === 'Sign in' ? 'Sign in' : 'Create account'}/>

          <div className={'flex flex-row w-full justify-center gap-4 items-center'}>
            <div className={styles.divider}></div>
            <p className={styles.dividerPlaceholder}>or</p>
            <div className={styles.divider}></div>
          </div>

          <GoogleAuthButton type={'button'} onClick={handleAuthViaGoogle}/>
        </div>
      </div>
    </div>
  </Container>
}

export default Authorization
