// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_loading__1uxxJ {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 32px */
  letter-spacing: 0.082px;
  color: #313333;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatsLoader/styles.module.scss","webpack://./src/constants/fonts.scss","webpack://./src/constants/colors.scss"],"names":[],"mappings":"AAGA;EACE,kCCFQ;EDGR,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,uBAAA;EACA,cETM;AFQR","sourcesContent":["@import 'constants/fonts.scss';\n@import 'constants/colors.scss';\n\n.loading {\n  font-family: $poppins;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 160%; /* 32px */\n  letter-spacing: 0.082px;\n  color: $black;\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `styles_loading__1uxxJ`
};
export default ___CSS_LOADER_EXPORT___;
