import React, { type FC, type MouseEvent, type PropsWithChildren } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

interface PropsType {
  handleToggleOverlay: (event: MouseEvent<HTMLDivElement>) => void
}

const OverlayWrapper: FC<PropsWithChildren<PropsType>> = ({
  children,
  handleToggleOverlay
}) => {
  return <div onClick={(event) => {
    handleToggleOverlay(event)
  }} className={clsx('w-full h-full absolute items-center justify-center flex top-0 z-20', styles.wrapper)}>
    {children}
  </div>
}

export default OverlayWrapper
