import React, { type FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { EstateLogo, EstateLogoBlurred } from 'images/index'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const AdvertisementBanner: FC = () => {
  return <div className={clsx(
    'flex flex-col items-start overflow-y-hidden py-12 sm:py-28 px-6 h-screen  self-start'
    , styles.wrapper)}>
    <div className={styles.sidebar}>
      <div>
        <h1 className={styles.heading}>Unlock the power of AI</h1>
        <p className={styles.subheading}>Find info in any city development code in just a second</p>
      </div>

      <LazyLoadImage src={EstateLogo}
                     placeholderSrc={EstateLogoBlurred}
                     effect={'blur'}
                     className={clsx(
                       'flex self-center justify-self-center pointer-events-none',
                       styles.advertisement)} alt={'estate building'}
      />
    </div>
  </div>
}

export default AdvertisementBanner
