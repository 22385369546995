import { UserService } from 'services/user'
import { store } from '../store'

// NPM
import axios from 'axios'

// Types
import type IUpload from 'types/IUpload'

export const uploadImage = async (image: Blob | undefined, contentType: string): Promise<void> => {
  try {
    const result = await store.dispatch(UserService.endpoints.generateUploadURI.initiate({ contentType }))
    if ('data' in result) {
      const file: IUpload = result.data
      await axios.put(file.putUrl, image, {
        headers: {
          'Content-Type': contentType
        }
      })
      await store.dispatch(UserService.endpoints.updateUser.initiate({ avatar: file.getUrl }))
    } else {
      const error = result.error
      console.error('Error occurred:', error)
    }
  } catch (error) {
    console.error('Error occurred:', error)
  }
}
