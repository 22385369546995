import React, { type FC, useState } from 'react'

// Components
import { InputWithIcon } from 'components/index'

// Icons
import { LockOutlined, MessageOutlined, VisibilityActive, VisibilityDisabled, CrossBlack } from 'icons/index'

interface Props {
  handleChangeUserCredentials: any
  user: { email: string, password: string, retype: string | undefined }
  handleResetEmailField: () => void
}

const RegistrationContext: FC<Props> = ({
  handleChangeUserCredentials,
  handleResetEmailField,
  user
}) => {
  const [hasPasswordSecured, setHasPasswordSecured] = useState(true)

  const handleTogglePasswordVisibility = (): void => {
    setHasPasswordSecured(prevState => !prevState)
  }
  return <>
    <form className={'flex flex-col gap-4 items-center justify-center w-full flex-col mt-6 xs:mt-8'}>
      <InputWithIcon maxLength={64} iconLeft={<MessageOutlined/>} placeholder={'Enter your email'}
                     iconRight={<CrossBlack/>} autoComplete={'email'}
                     onChange={handleChangeUserCredentials} onRightIconPress={handleResetEmailField} name={'email'}
                     value={user.email}/>

      <InputWithIcon maxLength={25}
                     iconLeft={<LockOutlined/>}
                     onRightIconPress={handleTogglePasswordVisibility}
                     placeholder={'Create your password'}
                     autoComplete={'new-password'}
                     iconRight={hasPasswordSecured ? <VisibilityDisabled/> : <VisibilityActive/>}
                     onChange={handleChangeUserCredentials}
                     type={hasPasswordSecured ? 'password' : 'text'}
                     name={'password'}
                     value={user.password}/>

      <InputWithIcon maxLength={25}
                     iconLeft={<LockOutlined/>}
                     autoComplete={'new-password'}
                     iconRight={hasPasswordSecured ? <VisibilityDisabled/> : <VisibilityActive/>}
                     placeholder={'Enter your password again'}
                     onRightIconPress={handleTogglePasswordVisibility}
                     onChange={handleChangeUserCredentials}
                     type={hasPasswordSecured ? 'password' : 'text'}
                     name={'retype'}
                     value={user.retype}/>
    </form>
  </>
}

export default RegistrationContext
