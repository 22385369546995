import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

// Services
import { UserService } from 'services/user'

// Types
import { type IUser } from 'types/IUser'
import { type UserState } from './type'

const initialState: UserState = {
  user: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => initialState,
    setUser: (state, action: PayloadAction<{ user: IUser }>) => {
      state.user = action.payload.user
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      UserService.endpoints.createUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload
      }
    )
    builder.addMatcher(
      UserService.endpoints.getUser.matchFulfilled,
      (state, { payload }) => {
        state.user = payload
      }
    )
  }
})

export const { setUser, logout } = userSlice.actions
export default userSlice.reducer
