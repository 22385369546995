import React, { type FC } from 'react'
import styles from './styles.module.scss'

// NPM
import clsx from 'clsx'

// Icons
import { AppLogoWhite } from 'icons/index'

// Components
import ChatsList from 'layout/Dashboard/components/Sidebar/ChatsList'
import UserCard from 'layout/Dashboard/components/Sidebar/UserCard'

const Sidebar: FC = () => {
  return <div className={clsx(
    'flex flex-col justify-between w-full h-screen px-2 sm:px-6 lg:px-5 xl:px-6 2xl:px-11 py-24'
    , styles.sidebar)}>
            <div>
                <figcaption className={'w-full'}>
                    <AppLogoWhite width={169} height={38}/>
                </figcaption>

                <ChatsList />
            </div>

            <UserCard/>
        </div>
}

export default Sidebar
