import React, { type FC } from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

interface Props {
  prompt: { _id: number, message: string }
}

const PromptExampleItem: FC<Props> = ({ prompt }) => {
  return <div className={clsx('w-full', styles.wrapper)}>
      <span className={styles.prompt}>{prompt.message}</span>
  </div>
}

export default PromptExampleItem
