// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__y-eTx {
  max-width: 721px;
  width: 100%;
  height: 48px;
  background-color: #F3F5F7;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}`, "",{"version":3,"sources":["webpack://./src/pages/Authorization/components/TabsWrapper/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EAEA,yBAAA;EACA,mBAAA;EAEA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,6BAAA;AADF","sourcesContent":[".wrapper {\n  max-width: 721px;\n  width: 100%;\n  height: 48px;\n\n  background-color: #F3F5F7;\n  border-radius: 12px;\n\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-around;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__y-eTx`
};
export default ___CSS_LOADER_EXPORT___;
