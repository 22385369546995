import React, { type FC } from 'react'
import Overlay from 'components/Overlay'
import { CrossBlack } from 'icons/index'
import clsx from 'clsx'
import { Elements } from '@stripe/react-stripe-js'
import { stripeProvider } from 'lib/stripe'
import PaymentForm from 'components/Modals/NewPaymentMethod/components/PaymentForm'

interface PropsType {
  handleToggleModal: () => void
}

const NewPaymentMethod: FC<PropsType> = ({ handleToggleModal }) => {
  const options = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap'
      }
    ]
  }

  return <Overlay handleToggleOverlay={handleToggleModal}>
    <div className={clsx('mx-2 max-w-[815px] w-full min-h-[208px] bg-white rounded-xl pt-4 pb-7 px-2')}
         onClick={handleToggleModal}>

      <div onClick={handleToggleModal} className={'flex justify-end items-end px-8'}>
        <CrossBlack/>
      </div>

      <div className={'px-14 mt-2'}>
        <Elements options={options} stripe={stripeProvider}>
          <PaymentForm handleToggleModal={handleToggleModal}/>
        </Elements>
      </div>

    </div>
  </Overlay>
}

export default NewPaymentMethod
