import React, { type FC, type MouseEvent } from 'react'
import styles from './styles.module.scss'

// NPM
import clsx from 'clsx'

// Icons
import { AppLogoWhite, MenuIcon } from 'icons/index'

// Components
import ChatsList from 'layout/Dashboard/components/Sidebar/ChatsList'
import UserCard from 'layout/Dashboard/components/Sidebar/UserCard'

interface PropsType {
  handleToggleSidebarMenu: () => void
}

const SidebarMenuMobile: FC<PropsType> = ({ handleToggleSidebarMenu }) => {
  const handleEventPropagation = (event: MouseEvent<HTMLDivElement>): void => {
    event.stopPropagation()
  }

  return <div onClick={handleEventPropagation} className={clsx(
    'flex flex-col absolute top-0 left-0 justify-between w-full h-full z-50 px-5 py-14'
    , styles.sidebar)}>
        <div>
            <figcaption className={'w-full flex flex-row items-center justify-between'}>
                <AppLogoWhite width={169} height={38}/>
                <div onClick={handleToggleSidebarMenu}>
                    <MenuIcon/>
                </div>
            </figcaption>

            <ChatsList />
        </div>

        <UserCard/>
    </div>
}

export default SidebarMenuMobile
