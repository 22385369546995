// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__Ik9qj {
  border: 2px solid #E7ECEF;
  background-color: #F3F5F7;
  border-radius: 20px;
}
.styles_wrapperOutlined__RsMy8 {
  background-color: transparent;
}

.styles_message__XzTU\\+ {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #313333;
  font-size: 12px;
  overflow-wrap: break-word;
}

.styles_refreshAction__wkxrO {
  width: 260px;
  display: flex;
  flex-direction: row;
  background-color: transparent;
  color: #6C7275;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

@media screen and (min-width: 640px) {
  .styles_message__XzTU\\+ {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/MessagesList/MessageItem/styles.module.scss","webpack://./src/constants/fonts.scss","webpack://./src/constants/colors.scss"],"names":[],"mappings":"AAGA;EACE,yBAAA;EACA,yBAAA;EACA,mBAAA;AADF;AAGE;EACE,6BAAA;AADJ;;AAKA;EACE,gCCVM;EDWN,gBAAA;EACA,cEfM;EFgBN,eAAA;EACA,yBAAA;AAFF;;AAKA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,cE1BK;EF2BL,eAAA;EACA,gCCxBM;EDyBN,gBAAA;AAFF;;AAKA;EACE;IACE,eAAA;EAFF;AACF","sourcesContent":["@import 'constants/colors.scss';\n@import 'constants/fonts.scss';\n\n.wrapper {\n  border: 2px solid #E7ECEF;\n  background-color: #F3F5F7;\n  border-radius: 20px;\n\n  &Outlined {\n    background-color: transparent;\n  }\n}\n\n.message {\n  font-family: $inter;\n  font-weight: 400;\n  color: $black;\n  font-size: 12px;\n  overflow-wrap: break-word;\n}\n\n.refreshAction {\n  width: 260px;\n  display: flex;\n  flex-direction: row;\n  background-color: transparent;\n  color: $gray;\n  font-size: 14px;\n  font-family: $inter;\n  font-weight: 600;\n}\n\n@media screen and (min-width: 640px) {\n  .message {\n    font-size: 16px;\n  }\n}\n","@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&family=Poppins:wght@400;500;600;700&family=Roboto&display=swap');\n\n$poppins: 'Poppins', sans-serif;\n$roboto: 'Roboto', sans-serif;\n$inter: 'Inter', sans-serif;\n","$gray: #6C7275;\n$black: #313333;\n$blue: #0084FF;\n$green: #29B473;\n$white: #FEFEFE;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__Ik9qj`,
	"wrapperOutlined": `styles_wrapperOutlined__RsMy8`,
	"message": `styles_message__XzTU+`,
	"refreshAction": `styles_refreshAction__wkxrO`
};
export default ___CSS_LOADER_EXPORT___;
