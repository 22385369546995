import React, { type FC, useEffect, useState } from 'react'
import './App.css'

// NPM
import * as Sentry from '@sentry/react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'

// Helpers
import { ProtectedRoute } from 'helpers/ProtectedRoute'
import { NonProtectedRoute } from 'helpers/NonProtectedRoute'

// Pages
import {
  Authorization,
  CreateNewPassword,
  Home,
  ProfileSettings,
  ProfileSetup,
  ResetPassword,
  Subscription,
  SubscriptionSettings,
  Verification
} from 'pages/index'

// Components
import { PageLoader } from 'components/index'

// Hooks
import { useAppSelector } from 'hooks/useRedux'
import { useGetCardsQuery, useGetSubscriptionsQuery } from 'services/billing'
import { useCookies } from 'react-cookie'
import { useGetChatsQuery } from 'services/chat'

const App: FC = () => {
  const navigate = useNavigate()
  const [cookies] = useCookies(['accessToken'])
  const [isLoading, setIsLoading] = useState(true)
  const user = useAppSelector(state => state.user.user)

  useGetSubscriptionsQuery({
    skip: cookies.accessToken === null
  })

  useGetCardsQuery({
    skip: cookies.accessToken === null
  })

  useGetChatsQuery({
    skip: cookies.accessToken === null
  })

  useEffect(() => {
    switch (location.pathname) {
      case '/': {
        if (user?.stripeCustomerId === null) {
          navigate('/billing')
        }
        break
      }
      case '/chats': {
        if (user?.stripeCustomerId === null) {
          navigate('/billing')
        }
        break
      }

      case '/settings/profile': {
        if (user?.stripeCustomerId === null) {
          navigate('/billing')
        }
        break
      }

      case '/settings/billing': {
        if (user?.stripeCustomerId === null) {
          navigate('/billing')
        }
        break
      }
    }

    const timeout = setTimeout(() => {
      setIsLoading(false)
    }, 400)

    return () => {
      clearTimeout(timeout)
    }
  }, [user, navigate])

  if (isLoading) {
    return <PageLoader/>
  }

  return (
    <Routes>
      <Route
        path={'/'}
        element={<NonProtectedRoute><Authorization/></NonProtectedRoute>}
      />
      <Route
        path={'/verification'}
        element={<NonProtectedRoute><Verification/></NonProtectedRoute>}
      />
      <Route
        path={'/personal'}
        element={<NonProtectedRoute><ProfileSetup/></NonProtectedRoute>}
      />
      <Route
        path={'/billing'}
        element={<NonProtectedRoute><Subscription/></NonProtectedRoute>}
      />
      <Route
        path={'/reset'}
        element={<NonProtectedRoute><ResetPassword/></NonProtectedRoute>}
      />

      <Route
        path={'/password/new'}
        element={<NonProtectedRoute><CreateNewPassword/></NonProtectedRoute>}
      />

      {/* Private Routes */}
      <Route
        path={'/chats'}
        element={<ProtectedRoute><Home/></ProtectedRoute>}
      />

      <Route
        path={'/c/:id'}
        element={<ProtectedRoute><Home/></ProtectedRoute>}
      />

      <Route
        path={'/settings/profile'}
        element={<ProtectedRoute><ProfileSettings/></ProtectedRoute>}
      />

      <Route
        path={'/settings/billing'}
        element={<ProtectedRoute><SubscriptionSettings/></ProtectedRoute>}
      />

      {/* NOT FOUND ROUTE */}
      <Route
        path="*"
        element={<NonProtectedRoute><Authorization/></NonProtectedRoute>}
      />
    </Routes>
  )
}

export default Sentry.withProfiler(() => (
  <BrowserRouter>
    <App/>
  </BrowserRouter>
))
