import React, { type ChangeEvent, type FC, type KeyboardEvent, useCallback, useEffect, useState } from 'react'
import styles from './styles.module.scss'

// NPM
import { useNavigate, useParams } from 'react-router'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Components
import { Dashboard } from 'layout/index'
import { Button, ChatsLoader, MessagesList, OverlayWrapper, SidebarMenuMobile } from 'components/index'
import PromptsExampleList from 'components/PromptExampleList'
import DeleteChat from 'components/Modals/DeleteChat'

// Icons
import { ChatOutlined, MenuIcon, SendOutlined } from 'icons/index'

// Hooks
import useMediaQuery from 'hooks/useMediaQuery'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import useLockBodyScroll from 'hooks/useLockBodyScroll'

// Services
import { useCreateChatMutation, useCreateNewMessageMutation, useGetMessageAnswerMutation } from 'services/chat'

// Store
import { resetChatItems, setChatItems } from 'store/chat-items/slice'

const Home: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const chatItems = useAppSelector(state => state.chatItems)
  const chat = useAppSelector(state => state.chats.chat)
  const subscription = useAppSelector(state => state.cards.subscription)

  useEffect(() => {
    dispatch(setChatItems({ items: null }))
  }, [navigate])

  useLockBodyScroll(true, 'root')

  const [createNewMessage] = useCreateNewMessageMutation()
  const [getMessageAnswer] = useGetMessageAnswerMutation()

  const isMobile = useMediaQuery('(max-width: 743px)')
  const [isSidebarActive, setIsSidebarActive] = useState<boolean>(false)
  const [chatMessage, setChatMessage] = useState('')

  const [createChat] = useCreateChatMutation()

  const handleCreateNewChat = (): void => {
    navigate('/chats')
    dispatch(resetChatItems())
  }

  const handleToggleSidebarMenu = useCallback(() => {
    setIsSidebarActive(prevState => !prevState)
  }, [])

  useEffect(() => {
    setIsSidebarActive(false)
  }, [!isMobile])

  const handleSendMessage = async (): Promise<void> => {
    setChatMessage('')
    if (subscription?.status !== 'canceled') {
      if (!chatItems.items?.length || !params.id) {
        await createChat({ title: chatMessage }).then(async (response: any) => {
          await createNewMessage({
            chatId: response.data?.id,
            data: chatMessage
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
          }).finally(async () => {
            await getMessageAnswer(response.data.id)
          })
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          navigate(`/c/${response.data.id}`)
        })
      } else {
        await createNewMessage({
          chatId: params.id,
          data: chatMessage
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
        }).finally(async () => {
          await getMessageAnswer(params.id)
        })
      }
    } else {
      toast.error('Renew your subscription', {
        position: 'top-right'
      })
    }
  }

  const handleKeyDownEvent = async (event: KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === 'Enter') {
      setChatMessage('')
      if (subscription?.status !== 'canceled') {
        if (!chatItems.items?.length || !params.id) {
          await createChat({ title: chatMessage }).then(async (response: any) => {
            await createNewMessage({
              chatId: response.data?.id,
              data: chatMessage
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
            }).finally(async () => {
              await getMessageAnswer(response.data.id)
            })
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            navigate(`/c/${response.data.id}`)
          })
        } else {
          await createNewMessage({
            chatId: params.id,
            data: chatMessage
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
          }).finally(async () => {
            await getMessageAnswer(params.id)
          })
        }
      } else {
        toast.error('Renew your subscription', {
          position: 'top-right'
        })
      }
    }
  }

  const handleChangeChatMessage = (e: ChangeEvent<HTMLInputElement>): void => {
    setChatMessage(e.target.value)
  }

  return <Dashboard>

    <div className={styles.wrapper}>

      <div className={'flex flex-row justify-between items-center md:justify-end pt-14 pb-2 z-20'}>

        {isMobile && <div onClick={handleToggleSidebarMenu}><MenuIcon /></div>}

        <div className={styles.action}>
          <Button
            renderIcon={<ChatOutlined />}
            onClick={handleCreateNewChat}
            disabled={false}
            title={'Create new chat'} />
        </div>
      </div>

      <div className={styles.messages}>
        {chatItems.loading && !chatItems?.items?.length
          ? <ChatsLoader />
          : chatItems?.items?.length
            ? <MessagesList />
            : <PromptsExampleList />
        }
      </div>

      <div className={styles.footer}>
        <input
          onChange={handleChangeChatMessage}
          onKeyDown={handleKeyDownEvent}
          value={chatMessage}
          placeholder={'Start typing...'}
          className={styles.input}
        />

        <div onClick={handleSendMessage}
          className={'absolute right-4 top-[1.2rem]'}>
          <SendOutlined />
        </div>

      </div>

      {isSidebarActive && <OverlayWrapper handleToggleOverlay={handleToggleSidebarMenu}>
        <SidebarMenuMobile handleToggleSidebarMenu={handleToggleSidebarMenu} />
      </OverlayWrapper>}

      {chat.state && <DeleteChat />}
      <ToastContainer
        draggable
        newestOnTop
        hideProgressBar
      />
    </div>

  </Dashboard>
}

export default Home
