import React, { type FC, useEffect, useState } from 'react'
import styles from '../../styles.module.scss'

// Icons
import { CheckmarkCircleOutlined } from 'icons/index'

// Services
import { useCreateSubscriptionMutation } from 'services/billing'

// Components
import { Button } from 'components/index'

// NPM
import clsx from 'clsx'
import { useNavigate } from 'react-router'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { type StripeCardElementChangeEvent } from '@stripe/stripe-js'

const CARD_OPTIONS = {
  showIcon: false,
  style: {
    base: {
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: 'Poppins, sans-serif',
      color: 'black',
      '::placeholder': {
        fontWeight: 500,
        color: '#BABABA'
      }
    },
    invalid: { color: 'black' }
  }
}

const PaymentForm: FC = () => {
  const navigate = useNavigate()
  const [createSubscription, {
    isLoading,
    isSuccess
  }] = useCreateSubscriptionMutation()
  const [, setIsPaymentSuccessful] = useState<boolean | null>(null)
  const [hasPaymentFilled, setHasPaymentFilled] = useState(false)

  // Stripe
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event: any): Promise<void> => {
    event.preventDefault()

    if ((stripe == null) || (elements == null)) {
      return
    }

    const cardElement: any = elements.getElement(CardElement)

    const {
      error,
      paymentMethod
    } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })

    if (error == null) {
      setIsPaymentSuccessful(true)
    }
    if ((paymentMethod?.id) != null) {
      await createSubscription(paymentMethod.id)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      navigate('/chats')
    }
  }, [isSuccess])

  const handleValidateCard = (details: StripeCardElementChangeEvent): void => {
    setHasPaymentFilled(details.complete)
  }

  return (
    <form className={'w-full'} onSubmit={handleSubmit}>
      <div className={clsx('form-row flex relative items-center justify-start flex-row w-full', styles.featuresCardInput)}>
        <div className='FormRow w-[90%]'>
          <CardElement options={CARD_OPTIONS} onChange={handleValidateCard} />
        </div>
        {hasPaymentFilled && <div className={'absolute right-4'}><CheckmarkCircleOutlined/></div>}
      </div>
      <div className={'w-full mt-8'}>
        <Button disabled={!hasPaymentFilled} isLoading={isLoading} type={'submit'} title={'Start a free trial'}/>
      </div>
    </form>
  )
}

export default PaymentForm
